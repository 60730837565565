import {
  Dialog,
  DialogActions,
  DialogButton,
  DialogContent,
  DialogTitle,
} from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import { useActiveActivity } from "React/Hooks/useActiveActivity";

export interface DialogConfirmCloseAuthoringProps {
  open: boolean;
  onClose: () => void;
}

export const DialogConfirmCloseAuthoring = ({
  open,
  onClose,
}: DialogConfirmCloseAuthoringProps) => {
  const { clearActiveActivity } = useActiveActivity();

  const handleDialogClosed = (action?: string) => {
    if (action === "accept") {
      clearActiveActivity();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onClosed={(e) => handleDialogClosed(e.detail.action)}
      renderToPortal
    >
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <p>
          Are you sure you want to leave authoring mode? Be sure you have saved
          your activity before exiting authoring mode.
        </p>
        <p>
          Please note that you will not be able to edit this activity again
          after you leave authoring mode. Only new activities can be created on
          this HASTI Conference Demo website.
        </p>
      </DialogContent>
      <DialogActions>
        <DialogButton action="cancel">Cancel</DialogButton>
        <DialogButton action="accept">Exit Authoring</DialogButton>
      </DialogActions>
    </Dialog>
  );
};
