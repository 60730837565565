import { EntityContext } from "Entities/EntityContext";
import React from "react";
import { ActivityDataSaver } from "UseCases/fetchAllActivities";
import {
  ActivityDuplicator,
  makeLaunchTemplateActivity,
} from "UseCases/launchTemplateActivity";

export default React.createContext<AppContext | undefined>(undefined);

export interface AppContext {
  entityContext: EntityContext;
  launchTemplateActivity: (id: string, title: string) => Promise<void>;
}

export function makeAppContext(
  entityContext: EntityContext,
  activityDuplicator: ActivityDuplicator,
  activitySaver: ActivityDataSaver
): AppContext {
  const launchTemplateActivity = makeLaunchTemplateActivity(
    entityContext,
    activityDuplicator,
    activitySaver
  );

  return {
    entityContext,
    launchTemplateActivity,
  };
}
