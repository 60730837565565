import { Dialog, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import { useActivityFromTemplate } from "React/Hooks/useActivityFromTemplate";
import { CreateActivityForm } from "./CreateActivityForm";

export const DialogCreateActivity = () => {
  const { showDialog, onDialogClosed } = useActivityFromTemplate();

  return (
    <Dialog renderToPortal={true} open={showDialog} onClosed={onDialogClosed}>
      <DialogTitle>
        <b>Enter a title for your activity</b>
      </DialogTitle>
      <CreateActivityForm />
    </Dialog>
  );
};
