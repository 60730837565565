import { Typography } from "@rmwc/typography";
import "@rmwc/typography/styles";

import style from "./SessionAbout.module.scss";

export const SessionAbout = () => {
  return (
    <div className={style["container"]}>
      <Typography tag="h3" use="subtitle1" className={style["heading"]}>
        About
      </Typography>
      <Typography tag="div" use="body1">
        Educators will be introduced to VIVED 3D models and their potential to
        support inquiry-based learning, augmented by video of educator/student
        testimonials and a brief hands-on exploratory activity. Then,
        participants will engage in an activity allowing them to explore a model
        from the student perspective. Participants will choose one of three
        models for their activity (frog dissection, human skeleton or the solar
        system) and will receive a full lesson plan and student guide to support
        classroom implementation of a similar activity. Following this
        experience, participants will share their feedback with VIVED staff by
        responding to prompts in a gallery format and completing a post-workshop
        survey.
      </Typography>
    </div>
  );
};
