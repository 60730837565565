import { ResourceCardPM, ResourceCardViewModel } from "PresentationManagers/ResourceCardPM";
import { useContext, useEffect, useRef, useState } from "react";
import ReactAppContext from "React/ReactAppContext";

export const useSessionResourceViewModel = (itemId: string) => {
  const dumbyViewModel = {
    title: "",
    subtitle: "",
    imageSrc: "https://via.placeholder.com/576x324.png",
    primaryAction: () =>
      console.log(
        `[useSessionResourceViewModel] - primary action clicked for card with itemId ${itemId}.`
      ),
  },
  pm = useRef<ResourceCardPM>(),
  [viewModel, setViewModel] = useState<ResourceCardViewModel>(dumbyViewModel);
  const appContext = useContext(ReactAppContext);

  useEffect(() => {
    if (!pm.current && appContext) {
      pm.current = new ResourceCardPM(itemId, appContext.entityContext, (vm) => {
        setViewModel(vm);
      });
    }

    return () => {
      pm.current?.dispose();
    };
  }, [appContext, itemId]);

  return viewModel;
};
