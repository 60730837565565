import ReactDOM from "react-dom";
import { EntityContext } from "Entities/EntityContext";
import { App } from "React/App";
import MainContext, { makeAppContext } from "./ReactAppContext";
import { ActivityDuplicator } from "UseCases/launchTemplateActivity";
import { ActivityDataSaver } from "UseCases/fetchAllActivities";

export function StartReact(
  entityContext: EntityContext,
  activityDuplicator: ActivityDuplicator,
  activitySaver: ActivityDataSaver
) {
  const appContext = makeAppContext(
    entityContext,
    activityDuplicator,
    activitySaver
  );

  ReactDOM.render(
    <MainContext.Provider value={appContext}>
      <App />
    </MainContext.Provider>,
    document.getElementById("root")
  );
}
