import makeGetAuthSecret from "Infrastructure/AuthSecurity/getAuthSecret";
import AWS from "aws-sdk"
import { JsonRequester, Options } from "../Fetch/types";
import endpoints from "./endpoints.json"
import makeGetChannelAcitivies, { GetChannelActivities } from "./calls/getChannelActivities";
import makeGetActivity, { GetActivity } from "./calls/getActivity";
import makeCreateActivity, { CreateActivity } from "./calls/createActivity";
import makeUpdateActivityData, { UpdateActivityData } from "./calls/updateActivityData";

export interface VivedApi_source {
  fetch: (method: "GET" | "PATCH" | "POST" | "PUT" | "DELETE", path: string, newData?: object, functionVersion?: "1" | "2") => Promise<Object>
}

export interface VivedApi {
  source: VivedApi_source
  getChannelActivities: GetChannelActivities,
  getActivity: GetActivity,
  createActivity: CreateActivity,
  updateActivityData: UpdateActivityData
}

export interface Params {
  query?: Map<string, string | number | boolean>;
  data?: object;
  method: "get";
  path: string;
  userToken?: string;
}

export class Source_Imp implements VivedApi_source {

  private endpoint: string = ""
  private authToken: string = ""
  private requestJson: JsonRequester;
  private getAuthSecret: () => Promise<string>;
  private JWT_sign: any;

  constructor(requestJson: JsonRequester, getAuthSecret: () => Promise<string>, JWT_sign: any) {
    this.requestJson = requestJson;
    this.getAuthSecret = getAuthSecret
    this.JWT_sign = JWT_sign

    this.setEndpointFromEnv()
  }

  setEndpointFromEnv() {
    if (process.env.REACT_APP_API_STAGING_ENV === "local") {
      this.endpoint = endpoints.local
    } else if (process.env.REACT_APP_API_STAGING_ENV === "dev") {
      this.endpoint = endpoints.development
    } else if (process.env.REACT_APP_API_STAGING_ENV === "staging") {
      this.endpoint = endpoints.staging
    } else if (process.env.REACT_APP_API_STAGING_ENV === "prod") {
      this.endpoint = endpoints.production
    } else {
      this.endpoint = endpoints.production
    }
  }

  async signAuthToken() {
    let token = this.authToken
    if(token) {
      return
    }

    let hasti_accessKey_id = "316d9433-2a4d-4e76-98b2-70a2cc522fc8",
    secret = await this.getAuthSecret(),
    exp = Math.floor(Date.now() / 1000) + 60 * 60 // 1 hour expiration

    token = this.JWT_sign(
      {
        id: hasti_accessKey_id,
        exp
      },
      secret
    )

    this.authToken = token;
  }

  async fetch(method: "GET" | "PATCH" | "POST" | "PUT" | "DELETE", path: string, newData?: object, functionVersion: "1" | "2" = "1") {
    return new Promise<Object>((resolve, reject) => {
      this.signAuthToken()
      .then(()=> {

        if(!this.authToken) {
          throw new Error("something is wrong with vived api's - auth token")
        }

        if(!this.endpoint) {
          throw new Error("something is wrong with vived api's - enpoint")
        }

        const options: Options = {
          method,
          headers: {
            Authorization: "Bearer " + this.authToken
          }
        };

        if(newData) {
          options.body = JSON.stringify(newData)
        }

        var url = new URL(path, this.endpoint);
        if(Number(functionVersion) > 1) {
          url.searchParams.set('function_version', functionVersion);
        }

        this.requestJson(url.toString(), options)
        .then((json) => {
          resolve(json);
        })
        .catch((e) => {
          reject(e);
        });
      })
    });
  }
}

export function makeVivedAPI(
  requestJson: JsonRequester,
  JWT_sign: any
): VivedApi {

  const  client = new AWS.SecretsManager({
    region: "us-east-1",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  }),
  getAuthSecret = makeGetAuthSecret(client)

  const api = new Source_Imp(requestJson, getAuthSecret, JWT_sign)

  return {
    source: api,
    getChannelActivities: makeGetChannelAcitivies(api),
    getActivity: makeGetActivity(api),
    createActivity: makeCreateActivity(api),
    updateActivityData: makeUpdateActivityData(api)
  }
}