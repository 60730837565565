import { Card, CardMedia, CardPrimaryAction } from "@rmwc/card";
import "@rmwc/card/styles";
import { Typography } from "@rmwc/typography";
import "@rmwc/typography/styles";

import placeholderImage from "media/placeholder-image_16x9_Vived_400x255.svg";
import style from "./CardWithImageAndTitle.module.scss";

export interface CardWithImageAndTitleProps {
  image: string;
  title: string;
  subtitle?: string;
  primaryAction: () => void;
}
export const CardWithImageAndTitle = ({
  image,
  title,
  subtitle,
  primaryAction,
}: CardWithImageAndTitleProps) => {
  const cardImage = image || placeholderImage;
  const renderCardTitle = () => {
    if (title) {
      return (
        <Typography
          className={style["single-line-ellipsis"]}
          use="subtitle1"
          tag="div"
        >
          {title}
        </Typography>
      );
    }
  };
  const renderCardSubtitle = () => {
    if (subtitle) {
      return (
        <Typography
          className={style["single-line-ellipsis"]}
          use="body2"
          tag="div"
        >
          {subtitle}
        </Typography>
      );
    }
  };

  return (
    <Card className={style["container"]}>
      <CardPrimaryAction onClick={primaryAction}>
        <CardMedia
          sixteenByNine
          className={style["image"]}
          style={{ backgroundImage: `url(${cardImage})` }}
        />
        <div className={style["content"]}>
          {renderCardTitle()}
          {renderCardSubtitle()}
        </div>
      </CardPrimaryAction>
    </Card>
  );
};
