import {
  TemplateActivityPM,
  TemplateActivityVM,
} from "PresentationManagers/TemplateActivityPM";
import { useContext, useEffect, useRef, useState } from "react";
import ReactAppContext from "React/ReactAppContext";

export const useActivityTemplateViewModel = (
  activityId: string
): TemplateActivityVM => {
  const appContext = useContext(ReactAppContext);
  const pm = useRef<TemplateActivityPM>();
  const [viewModel, setViewModel] = useState<TemplateActivityVM>({
    title: "",
    imageSrc: "https://via.placeholder.com/576x324.png",
    primaryAction: () =>
      console.log(
        `[useActivityTemplateViewModel] - primary action clicked for card with activityId ${activityId}.`
      ),
    button1: {
      label: "Educator Guide",
      url: "https://google.com",
    }
  });

  useEffect(() => {
    if (!pm.current && appContext) {
      pm.current = new TemplateActivityPM(
        activityId,
        appContext.entityContext,
        (vm) => {
          setViewModel(vm);
        }
      );
    }

    return () => {
      pm.current?.dispose();
    };
  }, [appContext, activityId]);

  return viewModel;
};
