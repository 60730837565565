import { id } from "aws-sdk/clients/datapipeline";
import { Activity } from "Entities/Activity";
import { EntityContext } from "Entities/EntityContext";
import { StudentRepository } from "Entities/StudentRepository";

export interface StudentVM {
    activityIds: string[]
}

export class StudentPM {
    private studentRepository: StudentRepository;
    private updateView: (viewModel: StudentVM) => void;

    dispose() {
        this.studentRepository.removeActivityAddedObserver(this.repositoryAddedTo);
        this.studentRepository.removeActivityRemovedObserver(this.repositoryRemovedFrom);
    }

    private repositoryAddedTo = (activity: Activity) => {
        this.doUpdateView();
    }

    private repositoryRemovedFrom = (string: id) => {
        this.doUpdateView();
    }

    private doUpdateView = () => {
        const activities = [...this.studentRepository.getAll()],
        activityIds = activities.map(activity => activity.id)
        const vm: StudentVM = {
            activityIds
        }
        this.updateView(vm);
    }

    constructor(
        entities: EntityContext,
        updateView: (viewModel: StudentVM) => void
    ) {
        this.studentRepository = entities.studentRepository;
        this.updateView = updateView;
        this.doUpdateView();

        this.studentRepository.addActivityAddedObserver(this.repositoryAddedTo);
        this.studentRepository.addActivityRemovedObserver(this.repositoryRemovedFrom);
    }
}