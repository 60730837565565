import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import { LinearProgress } from "@rmwc/linear-progress";
import "@rmwc/linear-progress/styles";
import { Typography } from "@rmwc/typography";

import { ActivityTemplates } from "React/Components/ActivityTemplates/ActivityTemplates";
import { ResourcesTab } from "React/Components/ResourcesTab/ResourcesTab";
import { SessionResources } from "React/Components/SessionResources/SessionResources";
import { StudentActivities } from "React/Components/StudentActivities/StudentActivities";
import { useLoadingResourcesViewModel } from "React/Hooks/useLoadingResourcesViewModel";
import { useResourceTabViewModel } from "React/Hooks/useResourceTabViewModel";

import style from "./Resources.module.scss";

export const Resources = () => {
  const {
    activeResourceTabIndex,
    resourceTabContent,
    resourceTabLabels,
    setActiveResourceTabIndex,
  } = useResourceTabViewModel();
  const {errorMessage, resourcesLoaded} = useLoadingResourcesViewModel();

  function onActivateTab(index: number) {
    setActiveResourceTabIndex(index);
  }

  const renderResourceError = () => {
    if (errorMessage){
      return (
        <Typography tag="p" use="body2" theme="error">
          <Icon icon="error" /> An error has occurred loading activities. Please try again. Contact support if the issue persists.
        </Typography>
      )
    } else return null;
  }

  const renderResourcesContent = () => {
    const activeTab = resourceTabContent[activeResourceTabIndex];

    if (activeTab === "SESSION_RESOURCES") {
      return <SessionResources />;
    } else if (activeTab === "ACTIVITY_TEMPLATES") {
      return <ActivityTemplates />;
    } else if (activeTab === "STUDENT_ACTIVITIES") {
      return <StudentActivities />;
    }
  };

  return (
    <div className={style["container"]}>
      <h2>Resources</h2>
      <ResourcesTab
        activeTabIndex={activeResourceTabIndex}
        onActivate={(i) => onActivateTab(i)}
        tabLabels={resourceTabLabels}
      />
      <LinearProgress closed={resourcesLoaded} />
      {renderResourceError()}
      {renderResourcesContent()}
    </div>
  );
};
