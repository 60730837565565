import { JsonRequester } from "Infrastructure/Fetch/types";
import { GetActivity } from "Infrastructure/VivedAPI/calls/getActivity";
import { ActivityDataFetcher } from "UseCases/fetchAllActivities";

export function makeActivityDataFetcher(getActivity: GetActivity, requestJson: JsonRequester): ActivityDataFetcher {
  return async function (activityId: string) {
    
    try {
      const activity = await getActivity(activityId)

      if(!activity.data) {
        throw new Error("missing data")
      }

      if (activity?.data.includes(".viveddata") || activity?.data.includes(".json")) {
        let realData = await requestJson(activity.data);
    
        return JSON.stringify(realData);
      } else {
        return activity.data
      }
    } catch(e: any) {
      throw new Error(e)
    }
  }
}