export type BasicPut = (url: string, body: any, contentType: string) => Promise<void>

export const basicPut: BasicPut = async (url: string, body: any, contentType: string) => {
  return await fetch(url, {
    method: "PUT",
    body,
    headers: {
      "Content-Type": contentType
    }
  })
  .then(async (response) => {
    if(response.status === 200) {
      return 
    } else {
      throw new Error("failed to upload large file")
    }
  })
  .catch(err => {
    throw new Error(err)
  })
}