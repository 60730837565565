import { Typography } from '@rmwc/typography';
import style from './TextFieldHelpTextErrorMessage.module.scss';

export interface TextFieldHelpTextErrorMessageProps {
  errors: any,
  inputName: string;
}
export const TextFieldHelpTextErrorMessage = ({errors, inputName}: TextFieldHelpTextErrorMessageProps) => {
  if (errors[inputName]) {
    if (errors[inputName].message){
      return (
        <Typography use="body2" tag="span" className={style["error-message"]}>
          {errors[inputName].message}
        </Typography>
      )
    } else return null;
  } else return null;
};
