import { BasicPut } from "Infrastructure/Fetch/basicPut"
import { VivedApi_source } from "Infrastructure/VivedAPI/source"
import uuid from "uuid"

type FolderTypes = "DataVariants" | "Thumbnails"
interface apiResponse {
  url: string,
  contentType: string
}
export type FileUploader = (fileData: string | Blob, fileExtention:string,  folder: FolderTypes) => Promise<string>

export default function makeUploadFile(api: VivedApi_source, basicPut: BasicPut): FileUploader {

  return async function ( fileData: string | Blob, fileExtention:string, folder: FolderTypes ) {
    let filename = `${uuid.v4()}.${fileExtention}`,
    file = new File([fileData], filename, {
      lastModified: Date.now()
    })

    let uploadUrl, contentType
    try {
      let res = await api.fetch("GET", `upload/large/${folder}/${filename}`, undefined, "2") as apiResponse
      uploadUrl = res.url
      contentType = res.contentType
    } catch( e:any ) {
      throw new Error(e)
    }
    
    try {
      await basicPut(uploadUrl, file, contentType)
    } catch (e:any) {
      throw new Error(e)
    }

    return filename
  }
}