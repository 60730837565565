import HastiChannelName from "Data/HastiChannelName";
import { FileUploader } from "Infrastructure/S3/uploadFile";
import { CreateActivity } from "Infrastructure/VivedAPI/calls/createActivity";
import { ActivityDuplicator, OriginalActivityDTO } from "UseCases/launchTemplateActivity";

export function makeActivityDuplicator(uploadFile: FileUploader, createActivity: CreateActivity): ActivityDuplicator {
  return async function ( original: OriginalActivityDTO, newTitle: string)  {

    let newDataFileName
    try {
      newDataFileName = await uploadFile(original.data, "json", "DataVariants")
    } catch(e: any) {
      throw new Error(e)
    }

    try {
      const duplicatedActivity = await createActivity({
        title: newTitle,
        data: newDataFileName,
        channel_name: HastiChannelName,
        description: original.description
      })

      return  {
        id: duplicatedActivity.SK,
        data: original.data,
        imgSrc: "",
        description: duplicatedActivity.description ?? "",
        title: duplicatedActivity.title ?? ""
      }
    } catch(e: any) {
      throw new Error(e)
    }
  }
}