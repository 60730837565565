import { useContext, useEffect, useRef, useState } from "react";
import {
  LoadingResourcesPM,
  LoadingResourcesVM,
} from "PresentationManagers/LoadingResourcesPM";
import ReactAppContext from "React/ReactAppContext";

export const useLoadingResourcesViewModel = (): LoadingResourcesVM => {
  const appContext = useContext(ReactAppContext);
  const pm = useRef<LoadingResourcesPM>();
  const [viewModel, setViewModel] = useState<LoadingResourcesVM>({
    resourcesLoaded: false,
    errorMessage: ""
  });

  useEffect(() => {
    if (!pm.current && appContext) {
      pm.current = new LoadingResourcesPM(appContext.entityContext, (vm) => {
        setViewModel(vm);
      });
    }

    return () => {
      pm.current?.dispose();
    };
  }, [appContext]);

  return viewModel;
};
