import {
  ActivityDuplicator,
  DuplicatedActivitedDTO,
  OriginalActivityDTO,
} from "UseCases/launchTemplateActivity";

export function makeStubbedDuplicator(): ActivityDuplicator {
  return stubbedActivityDuplicator;
}

const stubbedActivityDuplicator: ActivityDuplicator = (
  original: OriginalActivityDTO,
  newTitle: string
): Promise<DuplicatedActivitedDTO | undefined> => {
  return new Promise<DuplicatedActivitedDTO | undefined>((resolve, reject) => {
    const { data, description, imgSrc } = original;

    const newActivity: DuplicatedActivitedDTO = {
      data,
      description,
      id: `id_$${Date.now()}`,
      imgSrc,
      title: newTitle,
    };

    setTimeout(() => {
      resolve(newActivity);
    }, 1000);
  });
};
