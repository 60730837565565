import { Activity } from "Entities/Activity";
import { EntityContext } from "Entities/EntityContext";

export function makeLaunchActivityForConsumption(entities: EntityContext) {
  return async function launchActivityForConsumption(id: string) {
    const { studentRepository, resourceRepository, activeActivity } = entities;
    let activity: Activity | undefined = undefined;

    if (studentRepository.has(id)) {
      activity = studentRepository.get(id);
    } else if (resourceRepository.hasActivity(id)) {
      activity = resourceRepository.getActivity(id);
    }

    if (!activity) {
      activeActivity.setError(`Unable to find activity`);
      return;
    } else {
      activeActivity.setLoadingActivity(id);

      try {
        const data = await activity.getActivityData();
        if (!data) {
          throw new Error("Activity data is empty");
        }

        activeActivity.canAuthor = false;

        const lessonGuide = resourceRepository.getActivityGuide(id);
        if(lessonGuide) {
          activeActivity.auxiliaryContent1 = {
            label: "Educator Guide",
            fileSrc: lessonGuide
          }
        } else {
          activeActivity.auxiliaryContent1 = undefined;
        }

        activeActivity.setActivityIsReady(data);
      } catch (e) {
        activeActivity.setError(`Failed to get activity: ${e}`);
      }
    }
  };
}
