import { useContext, useEffect, useRef, useState } from "react";
import ReactAppContext from "React/ReactAppContext";
import { ResourcePM, ResourceVM } from "PresentationManagers/ResourcePM";
export interface SessionResourceListViewModel {
  itemIds: string[];
}

export const useSessionResourceListViewModel = (): SessionResourceListViewModel => {
  const appContext = useContext(ReactAppContext);
  const pm = useRef<ResourcePM>();
  const [viewModel, setViewModel] = useState<ResourceVM>({
    itemIds: []
  });

  useEffect(() => {
    if (!pm.current && appContext) {
      pm.current = new ResourcePM(appContext.entityContext, (vm) => {
        setViewModel(vm);
      });
    }

    return () => {
      pm.current?.dispose();
    };
  }, [appContext]);

  return viewModel;
};
