import { ActivityDataFetcher } from "UseCases/fetchAllActivities";
import activityData from "./stubbedActivity.json";

const stubbedActivityDataFetcher = (id: string): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const activityString = JSON.stringify(activityData);
    if (id === "id4") {
      resolve("");
    } else if (id === "id3") {
      setTimeout(() => {
        resolve(activityString);
      }, 3000);
    } else {
      resolve(activityString);
    }
  });
};

export function makeStubbedActivityDataFetcher(): ActivityDataFetcher {
  return stubbedActivityDataFetcher;
}