import { TabBar, Tab } from "@rmwc/tabs";
import "@rmwc/tabs/styles";

// import style from "./ResourcesTab.module.scss";

export interface ResourcesTabProps {
  activeTabIndex: number;
  onActivate: (index: number) => void;
  tabLabels: string[];
}
export const ResourcesTab = ({
  activeTabIndex,
  onActivate,
  tabLabels,
}: ResourcesTabProps) => {
  const renderTabs = () => {
    return tabLabels.map((tl, i) => {
      return <Tab key={i}>{tl}</Tab>;
    });
  };

  return (
    <TabBar
      activeTabIndex={activeTabIndex}
      onActivate={(evt) => onActivate(evt.detail.index)}
    >
      {renderTabs()}
    </TabBar>
  );
};
