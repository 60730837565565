import { GetChannelActivities } from "Infrastructure/VivedAPI/calls/getChannelActivities";
import {
  AllActivityFetcher,
  FetchActivityDTO,
} from "UseCases/fetchAllActivities";

export function makeAllActivitiesFetcher(getChannelAcitivies: GetChannelActivities): AllActivityFetcher {
  return async function () {
    
    try {
      const apiResponse = await getChannelAcitivies(),
      activities: FetchActivityDTO[] = apiResponse.activities.map((activity) => {
        return {
          id: activity.SK,
          title: activity.title ?? "",
          description: activity.description ?? "",
          imgSrc: activity.image ?? "",
        };
      })

      return activities
    } catch(e: any) {
      throw new Error(e)
    }
  }
}