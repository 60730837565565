import { ActivityDataSaver } from "UseCases/fetchAllActivities";

const stubbedActivityDataSaver = (id: string, data: string): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    console.log("Fake saving!");
    setTimeout(() => {
      resolve();
    }, 1000);
  });
};

export function makeStubbedActivityDataSaver(): ActivityDataSaver {
  return stubbedActivityDataSaver;
}
