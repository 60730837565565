import AWS from "aws-sdk"

export default function makeGetAuthSecret(client: AWS.SecretsManager) {

  return function (): Promise<any> {
    let secretName = process.env.REACT_APP_AWS_SECRET_MANAGER_NAME ?? "bad secret"

    return new Promise<any>((res, rej) => {
      client.getSecretValue({SecretId: secretName}, function(err, data) {
        
        if (err) {
          throw new Error(err.toString())
        }
        else {
          let secret:any = data?.SecretString
  
          if(secret) {
            secret = JSON.parse(secret) as any
            secret = Object.values(secret)[0]
          }
          
          res(secret)
        }
      });
    })
  }
}

