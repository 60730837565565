import { ActiveActivity } from "Entities/ActiveActivity";
import { Activity } from "Entities/Activity";
import { AppState } from "Entities/AppState";
import { EntityContext } from "Entities/EntityContext";
import { TemplateRepository } from "Entities/TemplateRepository";

export type LinkButtonVM = {
  label: string;
  url: string;
};
export interface TemplateActivityVM {
  title: string;
  imageSrc: string;
  primaryAction: () => void;
  button1: LinkButtonVM;
}

function makeOpenActivity(
  templateRepo: TemplateRepository,
  activityId: string,
  activeActivity: ActiveActivity
) {
  return function openActivity() {
    let allowAuthoring = true;
    if (window.innerHeight < 754 || window.innerWidth < 1024) {
      allowAuthoring = false;
    } 
    
    if (allowAuthoring) {
      templateRepo.createActivityFromTemplateID = activityId;
    } else {
      activeActivity.setError("Browser window is too small. Make the window larger and try again. Authoring is not supported on mobile devices at this time.");
    }
  };
}
export class TemplateActivityPM {
  private activityId: string;
  private templateRepository: TemplateRepository;
  private appState: AppState;
  private updateView: (viewModel: TemplateActivityVM) => void;
  private openActivity: () => void;

  dispose() {
    this.templateRepository.removeActivityAddedObserver(this.doUpdateView);
    this.templateRepository.removeActivityRemovedObserver(this.doUpdateView);
  }

  private doUpdateView = () => {
    if (!this.templateRepository.has(this.activityId)) {
      return;
    }
    const activity: Activity = this.templateRepository.get(this.activityId);

    const lessonPlanFile = this.templateRepository.getTeacherGuide(
      this.activityId
    );
    const lessonPlanSrc = lessonPlanFile
      ? this.appState.assetFolderURL + lessonPlanFile
      : "";

    const vm: TemplateActivityVM = {
      title: activity.title ?? "",
      imageSrc: activity.image ?? "",
      primaryAction: this.openActivity,
      button1: {
        label: "Educator Guide",
        url: lessonPlanSrc,
      }
    };

    this.updateView(vm);
  };

  constructor(
    activityId: string,
    entities: EntityContext,
    updateView: (viewModel: TemplateActivityVM) => void
  ) {
    this.activityId = activityId;
    this.templateRepository = entities.templateRepository;
    this.appState = entities.appState;
    this.openActivity = makeOpenActivity(
      this.templateRepository,
      this.activityId,
      entities.activeActivity
    );
    this.updateView = updateView;
    this.doUpdateView();
    this.templateRepository.addActivityAddedObserver(this.doUpdateView);
    this.templateRepository.addActivityRemovedObserver(this.doUpdateView);
  }
}
