import { Activity_fromApi } from "./types";
import { VivedApi_source } from "../source";
import HastiChannelId from "Data/HastiChannelId";

export type GetChannelActivities_Response = {
  total_count: number;
  activities: Activity_fromApi[];
};

export type GetChannelActivities = () => Promise<GetChannelActivities_Response>

export default function makeGetChannelAcitivies( api: VivedApi_source ): GetChannelActivities {
  return async function () {
    try {
      const apiResponse = await api.fetch( "GET", `public/channel/${HastiChannelId}/activities` ) as GetChannelActivities_Response;
      
      return apiResponse
    } catch (e: any) {
      throw new Error(e);
    }
  };
}
