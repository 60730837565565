import {
  ActivityPlayerPM,
  ActivityPlayerVM,
} from "PresentationManagers/ActivityPlayerPM";
import { useContext, useEffect, useRef, useState } from "react";
import ReactAppContext from "React/ReactAppContext";

export const useActivityPlayer = (): ActivityPlayerVM => {
  const appContext = useContext(ReactAppContext);
  const pm = useRef<ActivityPlayerPM>();
  const [viewModel, setViewModel] = useState<ActivityPlayerVM>({
    show: false,
    activityDataString: "",
    activityOptions: {
      canEdit: false,
      startInAuthor: false,
      title: ""
    },
    saveCallback: () => {
      return new Promise<void>((resolve, reject) => {
        resolve();
      });
    }
  });

  useEffect(() => {
    if (!pm.current && appContext) {
      pm.current = new ActivityPlayerPM(appContext.entityContext, (vm) => {
        setViewModel(vm);
      });
    }

    return () => {
      pm.current?.dispose();
    };
  }, [appContext]);

  return viewModel;
};
