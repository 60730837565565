import { useContext, useEffect, useRef, useState } from "react";
import ReactAppContext from "React/ReactAppContext";
import {
  ActivityFromTemplatePM,
  ActivityFromTemplateVM,
} from "PresentationManagers/ActivityFromTemplatePM";

export const useActivityFromTemplate = (): ActivityFromTemplateVM => {
  const appContext = useContext(ReactAppContext);
  const pm = useRef<ActivityFromTemplatePM>();
  const [viewModel, setViewModel] = useState<ActivityFromTemplateVM>({
    showDialog: false,
    createActivityFromTemplate: undefined,
    onDialogClosed: () => {},
  });

  useEffect(() => {
    if (!pm.current && appContext) {
      pm.current = new ActivityFromTemplatePM(
        appContext.entityContext,
        appContext.launchTemplateActivity,
        (vm) => {
          setViewModel(vm);
        }
      );
    }

    return () => {
      pm.current?.dispose();
    };
  }, [appContext]);

  return viewModel;
};
