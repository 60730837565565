import { IconButton } from "@rmwc/icon-button";
import { Typography } from "@rmwc/typography";
import "@rmwc/typography/styles";
import { ReactComponent as BoothSvg } from "media/HASTI-22_VIVED-Booth-Location.svg";
import boothSvg from "media/HASTI-22_VIVED-Booth-Location.svg";

import style from "./SessionBooth.module.scss";

export const SessionBooth = () => {
  return (
    <div className={style["container"]}>
      <Typography tag="h3" use="subtitle1" className={style["heading"]}>
        Visit Our Booth
      </Typography>
      <div className={style["content"]}>
        <div className={style["description"]}>
          <Typography tag="div" use="body1">
            To see how 3D Interactives can be used to engage students in your
            classroom, please visit us at Booth 21. We would love to hear from
            you.
          </Typography>
          {/* <Typography tag="div" use="body1" className={style["booth"]}>
            Booth # 21
          </Typography> */}
        </div>
        <div className={style["image-container"]}>
          <BoothSvg title="VIVED Booth #21" className={style["image"]} />
          <IconButton
            tag="a"
            title="View larger booth map"
            href={boothSvg}
            target="_blank"
            rel="noreferrer"
            icon="open_in_new"
          />
        </div>
      </div>
    </div>
  );
};
