import { Typography } from "@rmwc/typography";
import "@rmwc/typography/styles";

import style from "./AgendaItem.module.scss";

export interface AgendaItemProps {
  timeframe: string;
  line1: string;
  line2?: string;
  line3?: string;
}
export const AgendaItem = ({
  timeframe,
  line1,
  line2,
  line3,
}: AgendaItemProps) => {
  const renderLine = (line: string | undefined) => {
    if (line) {
      return (
        <Typography tag="div" use="body1">
          {line}
        </Typography>
      );
    }
  };
  return (
    <div className={style["container"]}>
      <Typography tag="div" use="body1" className={style["timeframe"]}>
        {timeframe}
      </Typography>
      <div className={style["description"]}>
        {renderLine(line1)}
        {renderLine(line2)}
        {renderLine(line3)}
      </div>
    </div>
  );
};
