import { Typography } from "@rmwc/typography";
import "@rmwc/typography/styles";

import { PresenterItem } from "React/Components/PresenterItem/PresenterItem";
import tomAvatar from "media/tom-avatar.png";
import hannahAvatar from "media/hannah-avatar.jpg";
import iritAvatar from "media/Irit-avatar.png";

import style from "./SessionPresenters.module.scss";

export const SessionPresenters = () => {
  return (
    <div className={style["container"]}>
      <Typography tag="h3" use="subtitle1" className={style["heading"]}>
        Presenters
      </Typography>
      <div className={style["list"]}>
        <PresenterItem
          name="Tom Nicknish"
          affiliation="VIVED Learning"
          title="President"
          email="tom.nicknish@vivedlearning.com"
          image={tomAvatar}
        />
        <PresenterItem
          name="Hannah Loeffelholz"
          affiliation="VIVED Learning"
          title="STEM SME"
          email="hspoonwood@gmail.com"
          image={hannahAvatar}
        />
        <PresenterItem
          name="Irit Coffino, M.Sc."
          affiliation="BES"
          title="Science EdTech Specialist"
          email="irit.coffino@gmail.com"
          image={iritAvatar}
        />
      </div>
    </div>
  );
};
