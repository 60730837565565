import { CardWithImageTitleAndButtons } from "React/Components/CardWithImageTitleAndButtons/CardWithImageTitleAndButtons";
import { useActivityTemplateViewModel } from "React/Hooks/useActivityTemplateViewModel";

export interface ActivityTemplateCardContainerProps {
  activityId: string;
}

export const ActivityTemplateCardContainer = ({
  activityId,
}: ActivityTemplateCardContainerProps) => {
  const { imageSrc, title, primaryAction, button1 } =
    useActivityTemplateViewModel(activityId);

  return (
    <CardWithImageTitleAndButtons
      image={imageSrc}
      title={title}
      primaryAction={primaryAction}
      button1={button1}
    />
  );
};
