import { AppState, ResourceTab } from "Entities/AppState";
import { EntityContext } from "Entities/EntityContext";

export interface ResourceTabVM {
    setActiveResourceTabIndex: (index: number) => void;
    activeResourceTabIndex: number;
    resourceTabLabels: string[];
    resourceTabContent: ResourceTab[];
}

const resourceTabLabels = [
    "Session Resources",
    "Activity Templates",
    "Participant Activities",
];
export class ResourceTabPM {
    private appState: AppState;
    private updateView: (viewModel: ResourceTabVM) => void;

    dispose() {
        this.appState.removeObserver(this.doUpdateView);
    }

    private doUpdateView = () => {
        const vm: ResourceTabVM = {
            setActiveResourceTabIndex: this.appState.setActiveResourceTabByIndex,
            activeResourceTabIndex: this.appState.resourceTabContent.indexOf(this.appState.activeResourceTab),
            resourceTabLabels: this.getResourceTabLabels(),
            resourceTabContent: this.appState.resourceTabContent
        }
        this.updateView(vm);
    }

    private getResourceTabLabels = (): string[] => {
        let labelsReturn: string[] = [];
        this.appState.resourceTabContent.forEach(tab => {
            if (tab === "SESSION_RESOURCES") {
                labelsReturn.push(resourceTabLabels[0]);
            }
            else if (tab === "ACTIVITY_TEMPLATES") {
                labelsReturn.push(resourceTabLabels[1]);
            }
            else if (tab === "STUDENT_ACTIVITIES") {
                labelsReturn.push(resourceTabLabels[2]);
            }
        });
        return labelsReturn;
    }

    constructor(
        entities: EntityContext,
        updateView: (viewModel: ResourceTabVM) => void
    ) {
        this.appState = entities.appState;
        this.updateView = updateView;
        this.doUpdateView();
        this.appState.addObserver(this.doUpdateView);
    }
}