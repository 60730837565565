import React from "react";
import { PropTypes } from "prop-types";

import style from "./PlayerShadowDom.module.scss";
import playerLoader from "utils/playerLoader";

/**
 * Renders the WebGL player inside a shadowDOM.
 *
 * Usage :
 * ```js
 * <PlayerShadowDom
 *   onPlayerCreated={player => this.onPlayerCreated(player)}
 *   size='normal'
 *   title={activity.title}
 *   appBar
 * />
 * ```
 * @arguments {Component<Props, State>}
 *
 */

class PlayerShadowDom extends React.Component {
  player = null;
  playerContainer = null;
  version = process.env.REACT_APP_PLAYER_VERSION;
  stage = process.env.REACT_APP_PLAYER_ENV;

  async componentDidMount() {
    const shadow = this.buildShadow();

    const entrypoints = [
      `https://dev.vivedlearning.com/master/3.11.3/main.js`,
      `https://dev.vivedlearning.com/master/3.11.3/style.css`,
    ];
    await playerLoader(entrypoints, shadow);

    this.startPlayer(shadow);
  }

  buildShadow() {
    let shadowParent = document.getElementById("player-shadow");
    let shadow = shadowParent.attachShadow({ mode: "open" });

    shadow.innerHTML = `
    <link type="text/css" href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
    <style> .player-container{ display: flex; height: 100%; width: 100%; } </style>
    <div id="player-container-authoring" class="player-container" />
    `;

    this.playerContainer = shadow.getElementById("player-container-authoring");

    Object.defineProperty(this.playerContainer, "ownerDocument", {
      value: shadow,
    });
    shadow.createElement = (...args) => document.createElement(...args);
    shadow.createElementNS = (...args) => document.createElementNS(...args);
    shadow.createTextNode = (...args) => document.createTextNode(...args);

    return shadow;
  }

  startPlayer(shadow) {
    let options = {
      ...this.props.playerOptions,
      playerID: "1acc340f-cf40-44eb-9e1d-5607c1ae4f07",
      secret:
        "IKIjmmhBqREJANHtlTmbQ6Y6GJ5kSszxe31Xu43LVQ3fRxz6AuMYc4zNPw8aUi16og1oa",
    };

    this.player = window.VIVEDPlayer.mount(
      this.playerContainer,
      options,
      shadow
    );

    this.props.onPlayerCreated(this.player);
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }

    if (this.playerContainer) {
      //window.VIVEDPlayer.unmount(this.playerContainer);
    }
  }

  render() {
    return <div id="player-shadow" className={style["container"]} />;
  }
}

PlayerShadowDom.defaultProps = {
  appBar: false,
};

PlayerShadowDom.propTypes = {
  appBar: PropTypes.bool,
  onPlayerCreated: PropTypes.func.isRequired,
  playerOptions: PropTypes.any,
  size: PropTypes.oneOf(["normal", "freeplay", "authoring", "embed", "fill"])
    .isRequired,
  title: PropTypes.string,
};

export default PlayerShadowDom;
