import { Activity, makeActivity } from "Entities/Activity";
import { EntityContext } from "Entities/EntityContext";
import { ActivityDataSaver } from "./fetchAllActivities";

export type ActivityDuplicator = (
  original: OriginalActivityDTO,
  newTitle: string
) => Promise<DuplicatedActivitedDTO | undefined>;

export interface OriginalActivityDTO {
  data: string;
  imgSrc: string;
  description: string;
}

export interface DuplicatedActivitedDTO {
  id: string;
  data: string;
  imgSrc: string;
  description: string;
  title: string;
}

export function makeLaunchTemplateActivity(
  entities: EntityContext,
  duplicator: ActivityDuplicator,
  saver: ActivityDataSaver
) {
  return async function launchTemplateActivity(id: string, title: string) {
    const { templateRepository, activeActivity, studentRepository } = entities;

    let templateActivity: Activity | undefined = undefined;
    if (templateRepository.has(id)) {
      templateActivity = templateRepository.get(id);
    }

    if (!templateActivity) {
      activeActivity.setError(`Unable to find template`);
      return;
    } else {
      activeActivity.setLoadingActivity(id);

      try {
        const data = await templateActivity.getActivityData();
        if (!data) {
          throw new Error("Template data is empty");
        }

        const original: OriginalActivityDTO = {
          data,
          description: templateActivity.description,
          imgSrc: templateActivity.image,
        };

        const duplicated = await duplicator(original, title);

        if (!duplicated) {
          throw new Error("Failed to duplicate");
        }

        const newActivity = makeActivity(duplicated.id);
        newActivity.description = duplicated.description;
        newActivity.title = duplicated.title;
        newActivity.image = duplicated.imgSrc;
        newActivity.setData(duplicated.data);

        newActivity.setActivityDataSaver(saver);

        studentRepository.add(newActivity);

        activeActivity.setLoadingActivity(duplicated.id);

        const teacherGuide = templateRepository.getTeacherGuide(id);
        if (teacherGuide) {
          activeActivity.auxiliaryContent1 = {
            label: "Educator Guide",
            fileSrc: teacherGuide,
          };
        } else {
          activeActivity.auxiliaryContent1 = undefined;
        }

        activeActivity.setActivityIsReady(data);

        activeActivity.canAuthor = true;
      } catch (e) {
        activeActivity.setError(`Failed to launch template: ${e}`);
      }
    }
  };
}
