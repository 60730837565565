import { ActiveActivity, makeActiveActivity } from "./ActiveActivity";
import { AppState, makeAppState } from "./AppState";
import {
  makeResourceRepository,
  ResourceRepository,
} from "./ResourceRepository";
import { makeStudentRepository, StudentRepository } from "./StudentRepository";
import {
  makeTemplateRepository,
  TemplateRepository,
} from "./TemplateRepository";

export interface EntityContext {
  appState: AppState;
  resourceRepository: ResourceRepository;
  templateRepository: TemplateRepository;
  studentRepository: StudentRepository;
  activeActivity: ActiveActivity;
}

export function makeEntityContext(): EntityContext {
  return {
    appState: makeAppState(),
    resourceRepository: makeResourceRepository(),
    templateRepository: makeTemplateRepository(),
    studentRepository: makeStudentRepository(),
    activeActivity: makeActiveActivity(),
  };
}
