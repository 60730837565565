import { Theme } from "@rmwc/theme";
import "@rmwc/theme/styles";
import { Typography } from "@rmwc/typography";
import "@rmwc/typography/styles";

import style from "./Header.module.scss";

export const Header = () => {
  return (
    <Theme wrap use="secondaryBg">
      <header className={style["container"]}>
        <div className={style["content"]}>
          <Typography tag="h1" use="headline4" theme="textPrimaryOnDark">
            Inquiry-Based STEM Learning Grounded in 3D Interactive Models
          </Typography>
          <Typography tag="div" use="subtitle1" theme="textPrimaryOnDark">
            Presented by VIVED Learning
          </Typography>
          <Typography tag="div" use="subtitle2" theme="textPrimaryOnDark">
            Februrary 14, 2022 | 2:45 - 3:30ET | Indianapolis C
          </Typography>
        </div>
      </header>
    </Theme>
  );
};
