import { ActiveActivity, AuxiliaryContent } from "Entities/ActiveActivity";
import { AppState } from "Entities/AppState";
import { EntityContext } from "Entities/EntityContext";

export type LinkButtonVM = {
  label: string;
  url: string;
};

export interface PlayerTopBarVM {
  closeButtonOnClick: () => void;
  button1?: LinkButtonVM;
  confirmClose: boolean;
}

export class PlayerTopBarPM {
  private activeActivity: ActiveActivity;
  private appState: AppState;

  dispose = () => {
    this.activeActivity.removeObserver(this.doUpdateView);
  };

  private doUpdateView = () => {
    const vm: PlayerTopBarVM = {
      closeButtonOnClick: this.activeActivity.clearActiveActivity,
      confirmClose: this.activeActivity.canAuthor,
    };

    if (this.activeActivity.auxiliaryContent1) {
      vm.button1 = this.makeButtonVM(this.activeActivity.auxiliaryContent1);
    }

    this.updateView(vm);
  };

  private makeButtonVM(auxContent: AuxiliaryContent): LinkButtonVM {
    const href = this.appState.assetFolderURL + auxContent.fileSrc;
    return {
      label: auxContent.label,
      url: href,
    };
  }

  constructor(
    entities: EntityContext,
    private updateView: (vm: PlayerTopBarVM) => void
  ) {
    this.activeActivity = entities.activeActivity;
    this.appState = entities.appState;

    this.activeActivity.addObserver(this.doUpdateView);

    this.doUpdateView();
  }
}
