import { Typography } from "@rmwc/typography";
import "@rmwc/typography/styles";

import { SessionAbout } from "React/Components/SessionAbout/SessionAbout";
import { SessionAgenda } from "React/Components/SessionAgenda/SessionAgenda";
import { SessionBooth } from "React/Components/SessionBooth/SessionBooth";
import { SessionPresenters } from "React/Components/SessionPresenters/SessionPresenters";

import style from "./SessionInformation.module.scss";

export const SessionInformation = () => {
  return (
    <div className={style["container"]}>
      <Typography tag="h2" use="headline6" className={style["heading"]}>
        Session Information
      </Typography>

      <SessionAbout />
      <SessionPresenters />
      <SessionAgenda />
      <SessionBooth />
    </div>
  );
};
