import { Portal } from "@rmwc/base";
import { DialogCreateActivity } from "./Components/DialogCreateActivity/DialogCreateActivity";
import { DialogLoadingActivity } from "./Components/DialogLoadingActivity/DialogLoadingActivity";
import { AccessFreeContent } from "./Containers/AccessFreeContent/AccessFreeContent";
import { Footer } from "./Containers/Footer/Footer";
import { Header } from "./Containers/Header/Header";
import { PlayerOverlay } from "./Containers/PlayerOverlay/PlayerOverlay";
import { Resources } from "./Containers/Resources/Resources";
import { SessionFeedback } from "./Containers/SessionFeedback/SessionFeedback";
import { SessionInformation } from "./Containers/SessionInformation/SessionInformation";
import style from "./App.module.scss";
import "./App.scss";


export const App = () => {
  return (
    <>
      <Header />
      <main className={style["main-content"]}>
        <SessionInformation />
        <Resources />
        <SessionFeedback />
        <AccessFreeContent />
      </main>
      <Footer />
      <DialogCreateActivity/>
      <DialogLoadingActivity />
      <Portal />
      <PlayerOverlay />
    </>
  );
};
