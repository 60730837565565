import JWT from "jsonwebtoken";

import { EntityContext, makeEntityContext } from "Entities/EntityContext";
import { makeStubbedActivityDataFetcher } from "Infrastructure/StubbedChannel/stubbedActivityDataFetcher";
import { makeStubbedActivityDataSaver } from "Infrastructure/StubbedChannel/stubbedActivityDataSaver";
import { makeStubbedDuplicator } from "Infrastructure/StubbedChannel/stubbedActivityDuplicator";
import { makeStubbedAllActivityFetcher } from "Infrastructure/StubbedChannel/stubbedAllActivityFetcher";
import { StartReact } from "React/StartReact";
import { AppData, applyAppData } from "UseCases/applyAppData";
import {
  ActivityDataFetcher,
  ActivityDataSaver,
  AllActivityFetcher,
  makeFetchAllActivities,
} from "UseCases/fetchAllActivities";
import { ActivityDuplicator } from "UseCases/launchTemplateActivity";
import appData from "./Data/AppData.json";
import { makeActivityDataSaver } from "Infrastructure/InfrastructureCordinator/activityDataSaver";
import { requestJson } from "Infrastructure/Fetch/requestJson";
import { makeActivityDataFetcher } from "Infrastructure/InfrastructureCordinator/activityDataFetcher";
import makeUploadFile from "Infrastructure/S3/uploadFile";
import { makeVivedAPI } from "Infrastructure/VivedAPI/source";
import { makeAllActivitiesFetcher } from "Infrastructure/InfrastructureCordinator";
import { makeActivityDuplicator } from "Infrastructure/InfrastructureCordinator/activityDuplicator";
import { basicPut } from "Infrastructure/Fetch/basicPut";

export class Main {
  private entityContext: EntityContext;

  constructor() {
    this.entityContext = makeEntityContext();
    this.entityContext.appState.assetFolderURL =
      window.location.href.replace(`index.html${window.location.search}`, "") +
      `assets/`;

    applyAppData(this.entityContext, appData as AppData);

    let activityDuplicator: ActivityDuplicator = makeStubbedDuplicator();
    let allActivityFetcher: AllActivityFetcher = makeStubbedAllActivityFetcher();
    let activityDataFetcher: ActivityDataFetcher = makeStubbedActivityDataFetcher();
    let activityDataSaver: ActivityDataSaver = makeStubbedActivityDataSaver();

    if (process.env.REACT_APP_API_STAGING_ENV === "prod") {
      const api = makeVivedAPI(requestJson, JWT.sign);

      const fileUploader = makeUploadFile(api.source, basicPut);

      activityDuplicator = makeActivityDuplicator( fileUploader, api.createActivity )
      allActivityFetcher = makeAllActivitiesFetcher( api.getChannelActivities );
      activityDataFetcher = makeActivityDataFetcher( api.getActivity, requestJson );
      activityDataSaver = makeActivityDataSaver( fileUploader, api.updateActivityData );
    }

    const getActivities = makeFetchAllActivities(
      this.entityContext,
      allActivityFetcher,
      activityDataFetcher,
      activityDataSaver
    );
    getActivities();

    StartReact(this.entityContext, activityDuplicator, activityDataSaver);
  }
}
