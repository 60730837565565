import { EntityContext } from "Entities/EntityContext";
import { StaticResourceAsset } from "Entities/ResourceRepository";
import { GuideLookup } from "Entities/TemplateRepository";

export interface AppData {
  sessionResourceActivityIDs: string[];
  activityTemplateIDs: string[];
  staticResources: {
    id: string;
    title: string;
    subtitle: string;
    file_src: string;
    img_src: string;
  }[];
  userGuides: {
    activity: string;
    src: string;
  }[];
  teacherGuides: {
    activity: string;
    src: string;
  }[];
  sessionActivityPDFs: {
    activity: string;
    src: string;
  }[];
}

export function applyAppData(entities: EntityContext, data: AppData) {
  const { appState, resourceRepository, templateRepository } = entities;

  data.sessionResourceActivityIDs.forEach((id) => {
    appState.addResourceId(id);
  });

  data.activityTemplateIDs.forEach((id) => {
    appState.addTemplateId(id);
  });

  data.staticResources.forEach((sr) => {
    const asset: StaticResourceAsset = {
      id: sr.id,
      title: sr.title,
      subtitle: sr.subtitle,
      img_src: sr.img_src,
      file_src: sr.file_src,
    };

    resourceRepository.addStaticResource(asset);
  });

  const teacherGuides: GuideLookup = data.teacherGuides.map((ug) => {
    return {
      activityId: ug.activity,
      guideSrc: ug.src,
    };
  });
  templateRepository.setTeacherGuideLookup(teacherGuides);

  const sessionGuides: GuideLookup = data.sessionActivityPDFs.map((ag) => {
    return {
      activityId: ag.activity,
      guideSrc: ag.src,
    };
  });

  resourceRepository.setActivityGuideLookup(sessionGuides);
}
