import { useContext, useEffect, useRef, useState } from "react";
import {
  ActiveActivityPM,
  ActiveActivityVM,
} from "PresentationManagers/ActiveActivityPM";
import ReactAppContext from "React/ReactAppContext";

export const useActiveActivity = (): ActiveActivityVM => {
  const appContext = useContext(ReactAppContext);
  const pm = useRef<ActiveActivityPM>();
  const [viewModel, setViewModel] = useState<ActiveActivityVM>({
    showError: false,
    clearActiveActivity: () => {},
    showLoadingSpinner: false,
    showPlayer: false,
    activityData: ""
  });

  useEffect(() => {
    if (!pm.current && appContext) {
      pm.current = new ActiveActivityPM(appContext.entityContext, (vm) => {
        setViewModel(vm);
      });
    }

    return () => {
      pm.current?.dispose();
    };
  }, [appContext]);

  return viewModel;
};
