import { EntityContext } from "Entities/EntityContext";
import { TemplateRepository } from "Entities/TemplateRepository";

export interface ActivityFromTemplateVM {
  showDialog: boolean;
  createActivityFromTemplate?: (newTitle: string) => Promise<void> | undefined;
  onDialogClosed: () => void;
}

function makeCreateActivityFromTemplate(
  launchTemplateActivity: (id: string, title: string) => Promise<void>,
  id: string
): (newTitle: string) => Promise<void> {
  return async function createActivityFromTemplate(newTitle: string) {
    await launchTemplateActivity(id, newTitle);
  };
}

export class ActivityFromTemplatePM {
  private launchTemplateActivity: (id: string, title: string) => Promise<void>;
  private templateRepo: TemplateRepository;
  private updateView: (vm: ActivityFromTemplateVM) => void;
  private previousVM: ActivityFromTemplateVM = {
    showDialog: false,
    onDialogClosed: () => (this.templateRepo.createActivityFromTemplateID = ""),
  };

  dispose = () => {
    this.templateRepo.removeAnyChangeObserver(this.doUpdateView);
  };

  private doUpdateView = () => {
    const vm: ActivityFromTemplateVM = { ...this.previousVM };

    const templateID = this.templateRepo.createActivityFromTemplateID.trim();
    if (templateID.length > 0) {
      vm.showDialog = true;
      const createActivityFromTemplate = makeCreateActivityFromTemplate(
        this.launchTemplateActivity,
        templateID
      );
      vm.createActivityFromTemplate = createActivityFromTemplate;
    } else {
      vm.showDialog = false;
    }

    this.previousVM = vm;
    this.updateView(vm);
  };

  constructor(
    entityContext: EntityContext,
    launchTemplateActivity: (id: string, title: string) => Promise<void>,
    updateView: (vm: ActivityFromTemplateVM) => void
  ) {
    this.templateRepo = entityContext.templateRepository;
    this.launchTemplateActivity = launchTemplateActivity;
    entityContext.templateRepository.addAnyChangeObserver(this.doUpdateView);

    this.updateView = updateView;
    this.doUpdateView();
  }
}
