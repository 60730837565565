import { Activity_fromApi } from "./types";
import { VivedApi_source } from "../source";

export type UpdateActivityData = ( activityId: string, newActivityDataInformation: NewActivityDataInformation ) => Promise<Activity_fromApi>

export type NewActivityDataInformation = {
  data: string
}
 
export default function makeUpdateActivityData(api: VivedApi_source): UpdateActivityData {
  return async function ( activityId: string, newActivityDataInformation: NewActivityDataInformation ) {
    try {
      const apiResponse = await api.fetch("PATCH", `public/activity/${activityId}`, newActivityDataInformation) as Activity_fromApi

      return apiResponse
    } catch (e: any) {
      throw new Error(e)
    }
  }
}