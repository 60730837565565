import { CircularProgress } from "@rmwc/circular-progress";
import "@rmwc/circular-progress/styles";
import {
  Dialog,
  DialogActions,
  DialogButton,
  DialogContent,
  DialogTitle,
} from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import { Typography } from "@rmwc/typography";
import { useActiveActivity } from "React/Hooks/useActiveActivity";

export interface DialogLoadingActivityProps {
  onClose?: () => void;
  onClosed?: () => void;
}

export const DialogLoadingActivity = ({
  onClose,
  onClosed,
}: DialogLoadingActivityProps) => {
  const { showError, errorMessage, showLoadingSpinner, clearActiveActivity } =
    useActiveActivity();

  const isLoading = showLoadingSpinner;
  const open = showError || showLoadingSpinner;

  const renderLoading = () => {
    return (
      <>
        <DialogTitle>Loading activity</DialogTitle>
        <DialogContent>
          <CircularProgress /> Loading activity...
        </DialogContent>
      </>
    );
  };

  const renderError = () => {
    return (
      <>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <p>The following error has occurred. Please try again.</p>
          <Typography tag="p" use="body1" theme="error">
            {errorMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <DialogButton action="close">Dismiss</DialogButton>
        </DialogActions>
      </>
    );
  };

  const renderContent = () => {
    if (showError) {
      return renderError();
    } else if (showLoadingSpinner) {
      return renderLoading();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      onClosed={() => {
        if (showError) {
          clearActiveActivity();
        }
        if (onClosed) {
          onClosed();
        }
      }}
      renderToPortal={true}
      preventOutsideDismiss={isLoading}
    >
      {renderContent()}
    </Dialog>
  );
};
