import { SessionResourceCardContainer } from "React/Containers/SessionResourceCardContainer/SessionResourceCardContainer";
import { useSessionResourceListViewModel } from "React/Hooks/useSessionResourceListViewModel";

import style from "./SessionResources.module.scss";

export const SessionResources = () => {
  const { itemIds } = useSessionResourceListViewModel();

  const renderCardList = () => {
    return itemIds.map((id) => {
      return <SessionResourceCardContainer itemId={id} key={id} />;
    });
  };

  return <div className={style["container"]}>{renderCardList()}</div>;
};
