import {
  Card,
  CardActionButton,
  CardActionButtons,
  CardActions,
  CardMedia,
  CardPrimaryAction,
} from "@rmwc/card";
import "@rmwc/card/styles";
import { Typography } from "@rmwc/typography";
import "@rmwc/typography/styles";

import { LinkButtonVM } from "PresentationManagers/TemplateActivityPM";
import placeholderImage from "media/placeholder-image_16x9_Vived_400x255.svg";
import style from "./CardWithImageTitleAndButtons.module.scss";

export interface CardWithImageTitleAndButtonsProps {
  image: string;
  title: string;
  subtitle?: string;
  primaryAction: () => void;
  button1?: LinkButtonVM;
  button2?: LinkButtonVM;
}

export const CardWithImageTitleAndButtons = ({
  image,
  title,
  subtitle,
  primaryAction,
  button1,
  button2,
}: CardWithImageTitleAndButtonsProps) => {
  const cardImage = image || placeholderImage;

  const renderCardTitle = () => {
    if (title) {
      return (
        <Typography
          className={style["single-line-ellipsis"]}
          use="subtitle1"
          tag="div"
        >
          {title}
        </Typography>
      );
    }
  };
  const renderCardSubtitle = () => {
    if (subtitle) {
      return (
        <Typography
          className={style["single-line-ellipsis"]}
          use="body2"
          tag="div"
        >
          {subtitle}
        </Typography>
      );
    }
  };
  const renderCardActionButton = (button?: LinkButtonVM) => {
    if (button) {
      return (
        <CardActionButton
          tag="a"
          label={button.label}
          href={button.url}
          target="_blank"
          rel="noreferrer"
        />
      );
    } else return null;
  };
  const renderCardActions = () => {
    if (button1 || button2) {
      return (
        <CardActions>
          <CardActionButtons>
            {renderCardActionButton(button1)}
            {renderCardActionButton(button2)}
          </CardActionButtons>
        </CardActions>
      );
    } else return null;
  };

  return (
    <Card className={style["container"]}>
      <CardPrimaryAction onClick={primaryAction}>
        <CardMedia
          sixteenByNine
          className={style["image"]}
          style={{ backgroundImage: `url(${cardImage})` }}
        />
        <div className={style["content"]}>
          {renderCardTitle()}
          {renderCardSubtitle()}
        </div>
      </CardPrimaryAction>
      {renderCardActions()}
    </Card>
  );
};
