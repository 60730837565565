import { useState } from "react";
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";
import { Theme } from "@rmwc/theme";
import "@rmwc/theme/styles";
import { LinkButtonVM } from "PresentationManagers/PlayerTopBarPM";


import { usePlayerTopBarViewModel } from "React/Hooks/usePlayerTopBarViewModel";
import { DialogConfirmCloseAuthoring } from "../DialogConfirmCloseAuthoring/DialogConfirmCloseAuthoring";

import style from "./PlayerTopBar.module.scss";

export const PlayerTopBar = () => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const { closeButtonOnClick, confirmClose, button1 } = usePlayerTopBarViewModel();

  const handleCloseOnClick = () => {
    if (confirmClose) {
      setConfirmDialogOpen(true);
    } else closeButtonOnClick();
  };

  const renderActionButton = (button?: LinkButtonVM) => {
    if (button) {
      return (
        <Button
          tag="a"
          outlined
          label={button.label}
          href={button.url}
          target="_blank"
          rel="noreferrer"
        />
      );
    } else return null;
  };

  const renderActionButtonsSection = () => {
    if (button1) {
      return (
        <div className={style["action-buttons__container"]}>
          {renderActionButton(button1)}
        </div>
      );
    }
  };



  return (
    <>
      <Theme use="background" wrap>
        <div className={style["container"]}>
          <IconButton icon="close" onClick={handleCloseOnClick} />
          {renderActionButtonsSection()}
        </div>
      </Theme>
      <DialogConfirmCloseAuthoring
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      />
    </>
  );
};
