import { JsonRequester, Options } from "./types";


export const requestJson: JsonRequester = (
  url: string,
  options?: Options
): Promise<Object> => {
  return new Promise<any>((resolve) => {
    fetch(url, options)
      .then((resp) => {
        if (!resp.ok) {
          throw new Error("Network response was not ok");
        }
        return resp.json();
      })
      .then((json) => {
        resolve(json);
      })
      .catch((e) => {
        throw new Error("There has been a problem with requesting json:" + e);
      });
  });
};
