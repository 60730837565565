import { Avatar } from "@rmwc/avatar";
import "@rmwc/avatar/styles";
import { Typography } from "@rmwc/typography";
import "@rmwc/typography/styles";

import style from "./PresenterItem.module.scss";

export interface PresenterItemProps {
  name: string;
  affiliation: string;
  title: string;
  email: string;
  image: string;
}
export const PresenterItem = ({
  name,
  affiliation,
  title,
  email,
  image,
}: PresenterItemProps) => {
  return (
    <div className={style["container"]}>
      <Avatar name={name} className={style["avatar"]} src={image} />
      <div className={style["details"]}>
        <Typography tag="div" use="subtitle2">
          {name}
        </Typography>
        <Typography tag="div" use="body2">
          {title}, {affiliation}
        </Typography>
        <Typography tag="div" use="body2">
          <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
            {email}
          </a>
        </Typography>
      </div>
    </div>
  );
};
