import { CardWithImageAndTitle } from "React/Components/CardWithImageAndTitle/CardWithImageAndTitle";
import { useSessionResourceViewModel } from "React/Hooks/useSessionResourceViewModel";

export interface SessionResourceCardContainerProps {
  itemId: string;
}
export const SessionResourceCardContainer = ({
  itemId,
}: SessionResourceCardContainerProps) => {
  const { imageSrc, title, subtitle, primaryAction } =
    useSessionResourceViewModel(itemId);
    
  return (
    <CardWithImageAndTitle
      image={imageSrc}
      title={title}
      subtitle={subtitle}
      primaryAction={primaryAction}
    />
  );
};
