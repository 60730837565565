import { Activity_fromApi } from "./types";
import { VivedApi_source } from "../source";
import HastiChannelId from "Data/HastiChannelId";

export type CreateActivity = (newActivityInformation: NewActivityInformation) => Promise<Activity_fromApi>

export interface NewActivityInformation {
    title: string
    data: string
    channel_name: string
    image?: string
    description?: string
}

export default function makeCreateActivity( api: VivedApi_source ): CreateActivity {
  return async function ( newActivityInformation: any ) {
    try {
      const apiResponse = await api.fetch( "POST", `public/activity/${HastiChannelId}`, newActivityInformation ) as Activity_fromApi

      return apiResponse
    } catch (e: any) {
      throw new Error(e);
    }
  };
}
