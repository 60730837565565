import { SubmitHandler, useForm } from "react-hook-form";
import { DialogActions, DialogButton, DialogContent } from "@rmwc/dialog";
import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";

import { TextFieldHelpTextErrorMessage } from "../TextFieldHelpTextErrorMessage/TextFieldHelpTextErrorMessage";
import { useCreateActivityFromTemplate } from "React/Hooks/useCreateActivityFromTemplate";
import { useActivityFromTemplate } from "React/Hooks/useActivityFromTemplate";

type CreateActivityFormInputs = {
  title: string;
};

export const CreateActivityForm = () => {
  const { register, handleSubmit, formState, reset } =
    useForm<CreateActivityFormInputs>({ mode: "onChange", defaultValues: {title: ""} });
  const { isValid: formIsValid, errors } = formState;
  const createActivity = useCreateActivityFromTemplate();
  const { createActivityFromTemplate } = useActivityFromTemplate();

  const onSubmit: SubmitHandler<CreateActivityFormInputs> = async (data) => {
    const { title } = data;
    createActivity(title);
    if (createActivityFromTemplate) {
      console.log("[CreateActivityForm] submit!");
      createActivityFromTemplate(title);
      reset();
    }
  };

  const renderTitleInput = () => {
    const inputLabel = "Title";
    // const inputName = "title";
    const inputType = "text";
    const inputMaxLength = 100;
    const inputMinLength = 4;
    // const inputPattern // ToDo: don't allow white space
    const validationErrMsg = {
      required: "The activity title is required.",
      maxLength: "Title must be 100 characters or less.",
      minLength: "Title must be at least 4 characters long.",
    };
    return (
      <TextField
        outlined
        style={{ width: "100%" }}
        required
        type={inputType}
        label={inputLabel}
        invalid={errors.title ? true : false}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: (
            <TextFieldHelpTextErrorMessage inputName="title" errors={errors} />
          ),
        }}
        {...register("title", {
          required: validationErrMsg.required,
          maxLength: {
            value: inputMaxLength,
            message: validationErrMsg.maxLength,
          },
          minLength: {
            value: inputMinLength,
            message: validationErrMsg.minLength,
          },
        })}
      />
    );
  };

  const renderDialogContent = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <p>
            Type a title below to create your activity and begin authoring.
            Choose something unique so you can find your activity later. It will
            not be possible to change the title once created.
          </p>
          {renderTitleInput()}
        </DialogContent>

        <DialogActions>
          <DialogButton action="close" type="button">
            Cancel
          </DialogButton>
          <DialogButton
            isDefaultAction
            type="submit"
            action="accept"
            disabled={!formIsValid}
          >
            Create
          </DialogButton>
        </DialogActions>
      </form>
    );
  };

  return renderDialogContent();
};
