import { ActivityTemplateCardContainer } from "React/Containers/ActivityTemplateCardContainer/ActivityTemplateCardContainer";
import { useActivityTemplateListViewModel } from "React/Hooks/useActivityTemplateListViewModel";

import style from "./ActivityTemplates.module.scss";

export const ActivityTemplates = () => {
  const { activityIds } = useActivityTemplateListViewModel();

  const renderCardlist = () => {
    return activityIds.map((id) => {
      return <ActivityTemplateCardContainer activityId={id} key={id} />;
    });
  };
  
  return <div className={style["container"]}>{renderCardlist()}</div>;
};
