import { AppEntities } from "@vived/app";

export type OnStateChange = () => void;
export type ResourceTab = "SESSION_RESOURCES" | "ACTIVITY_TEMPLATES" | "STUDENT_ACTIVITIES";
export type OnResourcesChange = () => void

export interface AppState {
    channelId: string;
    apiEndPoint: string;
    activeResourceTab: ResourceTab;
    resourceTabContent: ResourceTab[];
    assetFolderURL: string;

    isResourcesLoaded: boolean;
    fetchResourcesError: string;

    setActiveResourceTabByIndex: (index: number) => void;

    addResourceId: (id: string) => void;
    removeResourceId: (id: string) => void;
    getResourceIds: () => string[];

    addTemplateId: (id: string) => void;
    removeTemplateId: (id: string) => void;
    getTemplateIds: () => string[];

    addObserver: (observer: OnStateChange) => void;
    removeObserver: (observer: OnStateChange) => void;

    addResourcesObserver: (observer: OnResourcesChange) => void;
    removeResourcesObserver: (observer: OnResourcesChange) => void;
}

export function makeAppState(): AppState {
    return new AppStateImp();
}

class AppStateImp implements AppState {
    private observers = new AppEntities.ObserverList<void>();
    private resourceObservers = new AppEntities.ObserverList<void>();    

    assetFolderURL = "";

    private _isResourcesLoaded = false
    set isResourcesLoaded( val: boolean ) {

        this._isResourcesLoaded = val;
        this.resourceObservers.notify()
    }
    get isResourcesLoaded() {
        return this._isResourcesLoaded
    }

    private _fetchResourcesError = ""
    set fetchResourcesError( val: string ) {

        this._fetchResourcesError = val;
        this.resourceObservers.notify()
    }
    get fetchResourcesError() {

        return this._fetchResourcesError
    }

    readonly resourceTabContent: ResourceTab[] = ["SESSION_RESOURCES", "ACTIVITY_TEMPLATES", "STUDENT_ACTIVITIES"];

    setActiveResourceTabByIndex = (index: number) => {       
        const resourceTab = this.resourceTabContent[index];
        if(resourceTab){
            this.activeResourceTab = resourceTab;
        }
    }

    private memoizedChannelId = new AppEntities.MemoizedString(
        "",
        this.observers.notify
    );
    get channelId(): string {
        return this.memoizedChannelId.val;
    }
    set channelId(val: string) {
        this.memoizedChannelId.val = val;
    }

    private memoizedApiEndPoint = new AppEntities.MemoizedString(
        "",
        this.observers.notify
    );
    get apiEndPoint(): string {
        return this.memoizedApiEndPoint.val;
    }
    set apiEndPoint(val: string) {
        this.memoizedApiEndPoint.val = val;
    }

    private memoizedActiveResourceTab = new AppEntities.MemoizedString(
        "SESSION_RESOURCES",
        this.observers.notify
    );
    get activeResourceTab(): ResourceTab {
        return this.memoizedActiveResourceTab.val as ResourceTab;
    }
    set activeResourceTab(val: ResourceTab) {
        this.memoizedActiveResourceTab.val = val as string;
    }    

    private resourceIdsVal: string[] = [];
    addResourceId = (id: string) => {
        if (!this.resourceIdsVal.includes(id)) {
            this.resourceIdsVal.push(id);
            this.observers.notify();
        }
    }
    removeResourceId = (id: string) => {
        const index = this.resourceIdsVal.indexOf(id);
        if (index >= 0) {
            this.resourceIdsVal.splice(index, 1);
            this.observers.notify();
        }
    }
    getResourceIds = () => {
        return this.resourceIdsVal;
    }

    private templateIdsVal: string[] = [];
    addTemplateId = (id: string) => {
        if (!this.templateIdsVal.includes(id)) {
            this.templateIdsVal.push(id);
            this.observers.notify();
        }
    }
    removeTemplateId = (id: string) => {
        const index = this.templateIdsVal.indexOf(id);
        if (index >= 0) {
            this.templateIdsVal.splice(index, 1);
            this.observers.notify();
        }
    }
    getTemplateIds = () => {
        return this.templateIdsVal;
    }

    addObserver = (observer: OnStateChange) => {
        this.observers.add(observer);
    };

    removeObserver = (observer: OnStateChange) => {
        this.observers.remove(observer);
    };

    addResourcesObserver = (observer: OnResourcesChange) => {
        this.resourceObservers.add(observer);
    };

    removeResourcesObserver = (observer: OnResourcesChange) => {
        this.resourceObservers.remove(observer);
    };
}