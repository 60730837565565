import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import { Typography } from "@rmwc/typography";
import "@rmwc/typography/styles";

import style from "./SessionFeedback.module.scss";

export const SessionFeedback = () => {
  const emailAddress: string = "marketing@vivedlearning.com";
  const feedbackURL: string = "https://forms.gle/BiKtCdGHVwrQ6TeE9";

  return (
    <div className={style["container"]}>
      <Typography tag="h2" use="heading6">
        Post-Session Feedback
      </Typography>
      <Typography tag="p" use="body1">
        We are so glad that you were able to join us today. Please take a moment
        to provide us with your feedback.
      </Typography>
      <div className={style["button__container"]}>
        <Button tag="a" href={feedbackURL} raised icon="launch" target="_blank">
          Share Feedback
        </Button>
      </div>
      <Typography tag="p" use="body1">
        For more information about VIVED Learning, please contact:{" "}
        <a href={`mailto:${emailAddress}`}>marketing@vivedlearning.com</a>
      </Typography>
    </div>
  );
};
