import { ResourceTabPM, ResourceTabVM } from "PresentationManagers/ResourceTabPM";
import { useContext, useEffect, useRef, useState } from "react";
import ReactAppContext from "React/ReactAppContext";

export const useResourceTabViewModel = (): ResourceTabVM => {
  const appContext = useContext(ReactAppContext);
  const pm = useRef<ResourceTabPM>();
  const [viewModel, setViewModel] = useState<ResourceTabVM>({
    setActiveResourceTabIndex: () => { console.log("setActiveResourceTabIndex not set yet.") },
    activeResourceTabIndex: -1,
    resourceTabLabels: [],
    resourceTabContent: []
  });

  useEffect(() => {       
    if (!pm.current && appContext) {      
      pm.current = new ResourceTabPM(appContext.entityContext, (vm) => {
        setViewModel(vm);
      });
    }

    return () => {
      pm.current?.dispose();
    };
  }, [appContext]);
  
  return viewModel;
};
