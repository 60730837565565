import { useContext, useEffect, useRef, useState } from "react";
import ReactAppContext from "React/ReactAppContext";
import {
  StudentActivityPM,
  StudentActivityVM,
} from "PresentationManagers/StudentActivityPM";

export const useStudentActivityViewModel = (
  activityId: string
): StudentActivityVM => {
  const appContext = useContext(ReactAppContext);
  const pm = useRef<StudentActivityPM>();
  const [viewModel, setViewModel] = useState<StudentActivityVM>({
    title: "",
    imageSrc: "",
    primaryAction: () => {},
  });

  useEffect(() => {
    if (!pm.current && appContext) {
      pm.current = new StudentActivityPM(activityId, appContext.entityContext, (vm) => {
        setViewModel(vm);
      });
    }

    return () => {
      pm.current?.dispose();
    };
  }, [appContext, activityId]);

  return viewModel;
};
