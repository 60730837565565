import { Activity, makeActivity } from "Entities/Activity";
import { EntityContext } from "Entities/EntityContext";

export type ActivityDataFetcher = (id: string) => Promise<string>;

export type ActivityDataSaver = (id: string, activityData: string) => Promise<void>;

export interface FetchActivityDTO {
  id: string;
  title: string;
  description: string;
  imgSrc: string;
}
export type AllActivityFetcher = () => Promise<FetchActivityDTO[]>;

export function makeFetchAllActivities(
  entities: EntityContext,
  allActivityFetcher: AllActivityFetcher,
  activityDataFetcher: ActivityDataFetcher,
  activityDataSaver: ActivityDataSaver
) {
  
  return async function fetchAllActivities() {
    try {
      
      const dtos = await allActivityFetcher();
      dtos.forEach((dto) => {
        const activityEntity = DTOToActivity(dto);

        activityEntity.setDataGetter(() =>
          activityDataFetcher(activityEntity.id)
        );

        activityEntity.setActivityDataSaver( 
          activityDataSaver
        )

        if (entities.appState.getResourceIds().includes(activityEntity.id)) {
          entities.resourceRepository.addActivity(activityEntity);
        } else if (
          entities.appState.getTemplateIds().includes(activityEntity.id)
        ) {
          entities.templateRepository.add(activityEntity);
        } else {
          entities.studentRepository.add(activityEntity);
        }
      });

      entities.appState.isResourcesLoaded = true
    } catch(e: any) {

      entities.appState.fetchResourcesError = e.message
      throw new Error(e)
    }
  };
}

function DTOToActivity(dto: FetchActivityDTO): Activity {
  const activity = makeActivity(dto.id);
  activity.title = dto.title;
  activity.description = dto.description;
  activity.image = dto.imgSrc;

  return activity;
}
