import {
  AllActivityFetcher,
  FetchActivityDTO,
} from "UseCases/fetchAllActivities";
import stubbedData from "./StubbedChannelData.json";

const stubbedAllActivityFetcher: AllActivityFetcher = () => {
  return new Promise<FetchActivityDTO[]>((resolve, reject) => {
    const dtos: FetchActivityDTO[] = stubbedData.activites.map((activity) => {
      return {
        id: activity.id,
        description: activity.description,
        imgSrc: activity.imgSrc,
        title: activity.title,
      };
    });
    resolve(dtos);
  });
};

export function makeStubbedAllActivityFetcher(): AllActivityFetcher {
  return stubbedAllActivityFetcher;
}
