import { id } from "aws-sdk/clients/datapipeline";
import { Activity } from "Entities/Activity";
import { EntityContext } from "Entities/EntityContext";
import { TemplateRepository } from "Entities/TemplateRepository";

export interface TemplateVM {
    activityIds: string[]
}

export class TemplatePM {
    private templateRepository: TemplateRepository;
    private updateView: (viewModel: TemplateVM) => void;

    dispose() {
        this.templateRepository.removeActivityAddedObserver(this.repositoryAddedTo);
        this.templateRepository.removeActivityRemovedObserver(this.repositoryRemovedFrom);
    }

    private repositoryAddedTo = (activity: Activity) => {
        this.doUpdateView();
    }

    private repositoryRemovedFrom = (string: id) => {
        this.doUpdateView();
    }

    private doUpdateView = () => {
        const activities = [...this.templateRepository.getAll()];
        const activityIds = activities.map(a => a.id);
        const vm: TemplateVM = {
            activityIds
        }
        this.updateView(vm);
    }

    constructor(
        entities: EntityContext,
        updateView: (viewModel: TemplateVM) => void
    ) {
        this.templateRepository = entities.templateRepository;
        this.updateView = updateView;
        this.doUpdateView();

        this.templateRepository.addActivityAddedObserver(this.repositoryAddedTo);
        this.templateRepository.addActivityRemovedObserver(this.repositoryRemovedFrom);
    }
}