import { AppEntities } from "@vived/app";
import { Activity } from "./Activity";

export type OnActivityAdded = (activity: Activity) => void;
export type OnActivityRemoved = (id: string) => void;

export type GuideLookup = { activityId: string; guideSrc: string }[];

export interface StaticResourceAsset {
  id: string;
  title: string;
  subtitle: string;
  img_src: string;
  file_src: string;
}

export interface ResourceRepository {
  addActivity(activity: Activity): void;
  hasActivity(id: string): boolean;
  getActivity(id: string): Activity;
  getAllActivities(): Activity[];
  removeActivity(id: string): void;
  removeAllActivities(): void;
  addActivityAddedObserver(observer: OnActivityAdded): void;
  removeActivityAddedObserver(observer: OnActivityAdded): void;
  addActivityRemovedObserver(observer: OnActivityRemoved): void;
  removeActivityRemovedObserver(observer: OnActivityRemoved): void;

  addStaticResource: (resource: StaticResourceAsset) => void;
  getAllStaticResources: () => StaticResourceAsset[];
  hasStaticResource: (id: string) => boolean;
  getStaticResource: (id: string) => StaticResourceAsset;

  setActivityGuideLookup: (guides: GuideLookup) => void;
  getActivityGuide: (activityId: string) => string | undefined;
}

export function makeResourceRepository(): ResourceRepository {
  return new ResourceRepositoryImp();
}

class ResourceRepositoryImp implements ResourceRepository {
  private activityLookup = new Map<string, Activity>();
  private staticAssetsLookup = new Map<string, StaticResourceAsset>();
  private addObservers = new AppEntities.ObserverList<Activity>();
  private removeObservers = new AppEntities.ObserverList<string>();

  private studentGuideLookup = new Map<string, string>();

  addActivity = (activity: Activity): void => {
    if (this.activityLookup.has(activity.id)) {
      throw new Error(`Activity ID ${activity.id} already exists`);
    }

    this.activityLookup.set(activity.id, activity);
    this.addObservers.notify(activity);
  };

  hasActivity = (id: string): boolean => {
    const activity = this.activityLookup.get(id);
    return activity ? true : false;
  };

  getActivity = (id: string): Activity => {
    const activity = this.findActivity(id);
    return activity;
  };

  getAllActivities = (): Activity[] => {
    return Array.from(this.activityLookup.values());
  };

  removeActivity = (id: string): void => {
    const activity = this.findActivity(id);

    if (!activity) return;

    this.activityLookup.delete(id);
    this.removeObservers.notify(id);
  };

  removeAllActivities = (): void => {
    if (this.activityLookup.size === 0) return;

    const allActivityIds = Array.from(this.activityLookup.keys());
    allActivityIds.forEach((id) => this.removeActivity(id));

    this.activityLookup.clear();
  };

  addActivityAddedObserver = (observer: OnActivityAdded): void => {
    this.addObservers.add(observer);
  };

  removeActivityAddedObserver = (observer: OnActivityAdded): void => {
    this.addObservers.remove(observer);
  };

  addActivityRemovedObserver = (observer: OnActivityRemoved): void => {
    this.removeObservers.add(observer);
  };

  removeActivityRemovedObserver = (observer: OnActivityRemoved): void => {
    this.removeObservers.remove(observer);
  };

  addStaticResource = (resource: StaticResourceAsset): void => {
    this.staticAssetsLookup.set(resource.id, { ...resource });
  };

  getAllStaticResources = (): StaticResourceAsset[] => {
    return Array.from(this.staticAssetsLookup.values()).map((original) => {
      return { ...original };
    });
  };

  hasStaticResource = (id: string): boolean => {
    return this.staticAssetsLookup.has(id);
  };
  getStaticResource = (id: string): StaticResourceAsset => {
    const asset = this.staticAssetsLookup.get(id);
    if (!asset) {
      throw new Error("Unable to find asset by id: " + id);
    }

    return { ...asset };
  };

  setActivityGuideLookup = (guides: GuideLookup): void => {
    this.studentGuideLookup.clear();
    guides.forEach((guide) => {
      this.studentGuideLookup.set(guide.activityId, guide.guideSrc);
    });
  };
  getActivityGuide = (activityId: string): string | undefined => {
    return this.studentGuideLookup.get(activityId);
  };

  private findActivity(id: string): Activity {
    const activity = this.activityLookup.get(id);
    if (!activity) {
      throw new Error("[Resources] Could not find activity by ID " + id);
    }

    return activity;
  }
}
