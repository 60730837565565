import { Activity } from "Entities/Activity";
import { EntityContext } from "Entities/EntityContext";
import { StudentRepository } from "Entities/StudentRepository";
import { makeLaunchActivityForConsumption } from "UseCases/launchActivityForConsumption";

export interface StudentActivityVM {
  title: string;
  imageSrc: string;
  primaryAction: () => void;
}

export class StudentActivityPM {
  private activity: Activity;
  private studentRepository: StudentRepository;
  private updateView: (viewModel: StudentActivityVM) => void;

  openActivityForConsumptionUC: (id:string)=>void;

  dispose() {
    this.activity.removeObserver(this.doUpdateView);
  }

  private doUpdateView = () => {
    const vm: StudentActivityVM = {
      title: this.activity.title ?? "",
      imageSrc: this.activity.image ?? "",
      primaryAction: ()=>this.openActivityForConsumptionUC(this.activity.id),
    };

    this.updateView(vm);
  };

  constructor(
    activityId: string,
    entities: EntityContext,
    updateView: (viewModel: StudentActivityVM) => void
  ) {
    this.studentRepository = entities.studentRepository;

    if (!this.studentRepository.has(activityId)) {
      throw new Error("Cannot find activity");
    }

    this.activity = this.studentRepository.get(activityId);
    this.openActivityForConsumptionUC = makeLaunchActivityForConsumption(entities);

    this.updateView = updateView;
    this.doUpdateView();
    this.activity.addObserver(this.doUpdateView);
  }
}
