import { Player } from "React/Components/Player/Player";
import { PlayerTopBar } from "React/Components/PlayerTopBar/PlayerTopBar";
import { useActivityPlayer } from "React/Hooks/useActivityPlayer";

import style from "./PlayerOverlay.module.scss";

export const PlayerOverlay = () => {
  const { show } = useActivityPlayer();
  if (show) {
    return (
      <div className={style["container"]}>
        <PlayerTopBar />
        <Player />
      </div>
    );
  } else return null;
};
