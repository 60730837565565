import { useContext, useEffect, useRef, useState } from "react";
import ReactAppContext from "React/ReactAppContext";
import { StudentPM, StudentVM } from "PresentationManagers/StudentPM";

export interface StudentActivityListViewModel {
  activityIds: string[];
}

export const useStudentActivityListViewModel =
  (): StudentActivityListViewModel => {
    const appContext = useContext(ReactAppContext);
    const pm = useRef<StudentPM>();
    const [viewModel, setViewModel] = useState<StudentVM>({
      activityIds: []
    });
  
    useEffect(() => {
      if (!pm.current && appContext) {
        pm.current = new StudentPM(appContext.entityContext, (vm) => {
          setViewModel(vm);
        });
      }
  
      return () => {
        pm.current?.dispose();
      };
    }, [appContext]);
  
    return viewModel;
  };
