import { Activity } from "Entities/Activity";
import { AppState } from "Entities/AppState";
import { EntityContext } from "Entities/EntityContext";
import {
  ResourceRepository,
  StaticResourceAsset,
} from "Entities/ResourceRepository";
import { makeLaunchActivityForConsumption } from "UseCases/launchActivityForConsumption";

export interface ResourceCardViewModel {
  title: string;
  subtitle: string;
  imageSrc: string;
  primaryAction: () => void;
}

export class ResourceCardPM {
  private itemId: string;
  private resourceRepository: ResourceRepository;
  private activity?: Activity;
  private resource?: StaticResourceAsset;
  private appState: AppState;

  private updateView: (viewModel: ResourceCardViewModel) => void;

  openActivityForConsumptionUC: (id: string) => void;

  dispose = () => {
    this.activity?.removeObserver(this.doUpdateView);
  };

  private doUpdateView = () => {
    if (this.activity) {
      const vm: ResourceCardViewModel = {
        title: this.activity.title ?? "",
        subtitle: "Activity",
        imageSrc: this.activity.image ?? "",
        primaryAction: () =>
          this.openActivityForConsumptionUC(this.activity?.id ?? ""),
      };

      this.updateView(vm);
    } else if (this.resource) {
      const urlToFile = this.appState.assetFolderURL + this.resource.file_src;
      const urlToImg = this.appState.assetFolderURL + this.resource.img_src;

      const vm: ResourceCardViewModel = {
        title: this.resource.title ?? "",
        subtitle: this.resource.subtitle ?? "",
        imageSrc: urlToImg,
        primaryAction: () => {
          window.open(urlToFile);
        },
      };
      this.updateView(vm);
    }
  };

  constructor(
    itemId: string,
    entities: EntityContext,
    updateView: (viewModel: ResourceCardViewModel) => void
  ) {
    this.itemId = itemId;
    this.updateView = updateView;
    this.openActivityForConsumptionUC =
      makeLaunchActivityForConsumption(entities);
    this.resourceRepository = entities.resourceRepository;
    this.appState = entities.appState;

    if (this.resourceRepository.hasActivity(itemId)) {
      this.activity = this.resourceRepository.getActivity(itemId);
      this.activity.addObserver(this.doUpdateView);
      this.doUpdateView();
    } else if (this.resourceRepository.hasStaticResource(itemId)) {
      this.resource = this.resourceRepository.getStaticResource(itemId);
      this.doUpdateView();
    }
  }
}
