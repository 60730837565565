import {
  PlayerTopBarPM,
  PlayerTopBarVM,
} from "PresentationManagers/PlayerTopBarPM";
import { useContext, useEffect, useRef, useState } from "react";
import ReactAppContext from "React/ReactAppContext";

export const usePlayerTopBarViewModel = (): PlayerTopBarVM => {
  const appContext = useContext(ReactAppContext);
  const pm = useRef<PlayerTopBarPM>();
  const [viewModel, setViewModel] = useState<PlayerTopBarVM>({
    closeButtonOnClick: () => {},
    confirmClose: false
  });

  useEffect(() => {
    if (!pm.current && appContext) {
      pm.current = new PlayerTopBarPM(appContext.entityContext, (vm) => {
        setViewModel(vm);
      });
    }

    return () => {
      pm.current?.dispose();
    };
  }, [appContext]);

  return viewModel;
};
