import { id } from "aws-sdk/clients/datapipeline";
import { Activity } from "Entities/Activity";
import { EntityContext } from "Entities/EntityContext";
import { ResourceRepository } from "Entities/ResourceRepository";

export interface ResourceVM {
    itemIds: string[]
}

export class ResourcePM {
    private resourceRepository: ResourceRepository;
    private updateView: (viewModel: ResourceVM) => void;

    dispose() {
        this.resourceRepository.removeActivityAddedObserver(this.repositoryAddedTo);
        this.resourceRepository.removeActivityRemovedObserver(this.repositoryRemovedFrom);
    }

    private repositoryAddedTo = (activity: Activity) => {
        this.doUpdateView();
    }

    private repositoryRemovedFrom = (string: id) => {
        this.doUpdateView();
    }

    private doUpdateView = () => {
        const activities = [...this.resourceRepository.getAllActivities()],
        resourceIDs = activities.map(activity => activity.id)

        this.resourceRepository.getAllStaticResources().forEach(sr => {
          resourceIDs.push(sr.id);
        })

        const vm: ResourceVM = {
            itemIds: [...resourceIDs]
        }
        this.updateView(vm);
    }

    constructor(
        entities: EntityContext,
        updateView: (viewModel: ResourceVM) => void
    ) {
        this.resourceRepository = entities.resourceRepository;
        this.updateView = updateView;
        this.doUpdateView();

        this.resourceRepository.addActivityAddedObserver(this.repositoryAddedTo);
        this.resourceRepository.addActivityRemovedObserver(this.repositoryRemovedFrom);
    }
}