// import { Activity } from "Entities/Activity";
import { Activity_fromApi } from "./types";
import { VivedApi_source } from "../source";

export type GetActivity = (id: string) => Promise<Activity_fromApi>;
 
export default function makeGetActivity( api: VivedApi_source ): GetActivity {
  return async function (activityId:string) {
    try {
      const apiResponse = await api.fetch("GET", `channels/anyChannelId/activities/${activityId}`) as Activity_fromApi

      return apiResponse
    } catch (e: any) {
      throw new Error(e)
    }
  }
}