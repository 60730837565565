import { useActivityPlayer } from "React/Hooks/useActivityPlayer";
import PlayerShadowDom from "../PlayerShadowDom/PlayerShadowDom";
import style from "./Player.module.scss";

export const Player = () => {
  const { activityDataString, activityOptions, saveCallback } =
    useActivityPlayer();

  function onPlayerCreated(player: any) {
    player.LoadActivityFromString(activityDataString, activityOptions);
  }

  async function onSaveCallback(activityData: string) {
    await saveCallback(activityData);
    (window as any).VIVEDPlayer.OnSaveActivityIsComplete(true);
  }

  return (
    <div className={style["container"]}>
      <PlayerShadowDom
        onPlayerCreated={(player: any) => onPlayerCreated(player)}
        size="normal"
        playerOptions={{ showTitleBar: true, onSaveCallback: onSaveCallback }}
      />
    </div>
  );
};
