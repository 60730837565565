import { ActiveActivity } from "Entities/ActiveActivity";
import { EntityContext } from "Entities/EntityContext";

export interface ActiveActivityVM {
  showPlayer: boolean;
  showError: boolean;
  showLoadingSpinner: boolean;
  errorMessage?: string;
  activityData: string;

  clearActiveActivity: () => void;
}

export class ActiveActivityPM {
  private activeActivity: ActiveActivity;
  private updateView: (vm: ActiveActivityVM) => void;

  dispose = () => {
    this.activeActivity.removeObserver(this.doUpdateView);
  };

  private doUpdateView = () => {
    const vm: ActiveActivityVM = {
      showPlayer: false,
      showError: false,
      showLoadingSpinner: false,
      activityData: "",
      clearActiveActivity: this.activeActivity.clearActiveActivity,
    };

    if (this.activeActivity.state === "ERROR") {
      vm.showError = true;
      vm.errorMessage = this.activeActivity.errorMessage;
    } else if (this.activeActivity.state === "LOADING") {
      vm.showLoadingSpinner = true;
    } else if (this.activeActivity.state === "READY") {
      vm.showPlayer = true;
      vm.activityData = this.activeActivity.activityData;
    }

    this.updateView(vm);
  };

  constructor(
    entities: EntityContext,
    updateView: (vm: ActiveActivityVM) => void
  ) {
    this.activeActivity = entities.activeActivity;
    entities.activeActivity.addObserver(this.doUpdateView);

    this.updateView = updateView;
    this.doUpdateView();
  }
}
