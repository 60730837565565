import { StudentActivityCardContainer } from "React/Containers/StudentActivityCardContainer/StudentActivityCardContainer";
import { useStudentActivityListViewModel } from "React/Hooks/useStudentActivityListViewModel";

import style from "./StudentActivities.module.scss";

export const StudentActivities = () => {
  const { activityIds } = useStudentActivityListViewModel();

  const renderCardList = () => {
    return activityIds.map((id) => {
      return <StudentActivityCardContainer activityId={id} key={id} />;
    });
  };

  return <div className={style["container"]}>{renderCardList()}</div>;
};
