import { IconButton } from "@rmwc/icon-button";
import { Theme } from "@rmwc/theme";
import "@rmwc/theme/styles";
import { Typography } from "@rmwc/typography";
import "@rmwc/typography/styles";

import { ReactComponent as VivedLogo } from "media/vived-banner-nobkgd.svg";
import { ReactComponent as FacebookLogo } from "media/facebook-icon-monochrome-dark.svg";
import { ReactComponent as TwitterLogo } from "media/twitter-icon-monochrome-dark.svg";
import { ReactComponent as InstagramLogo } from "media/instagram-icon-monochrome-dark.svg";
import { ReactComponent as YoutubeLogo } from "media/youtube-icon-monochrome-dark.svg";

import style from "./Footer.module.scss";

export const Footer = () => {
  const today = new Date();
  const currentYear = today.getFullYear();

  const renderLogo = () => {
    return (
      <div className={style["logo-container"]}>
        <VivedLogo className={style["logo"]} />
      </div>
    );
  };
  const renderLinks = () => {
    return (
      <div className={style["links-container"]}>
        <Typography use="overline" theme="onSecondary">
          Links
        </Typography>
        <div>
          <Typography
            use="body2"
            tag="a"
            theme="onSecondary"
            href="https://vivedlearning.com"
            target="_blank"
            rel="noreferrer"
            className={style["text-link"]}
          >
            VIVED Learning
          </Typography>
        </div>
        <div>
          <Typography
            use="body2"
            tag="a"
            theme="onSecondary"
            href="https://vlp.vivedlearning.com"
            target="_blank"
            rel="noreferrer"
            className={style["text-link"]}
          >
            VIVED Learning Platform
          </Typography>
        </div>
      </div>
    );
  };
  const renderSocialIconButtons = () => {
    return (
      <div className={style["links-container"]}>
        <Typography
          use="overline"
          theme="onSecondary"
          style={{ paddingLeft: "12px" }}
        >
          Social
        </Typography>
        <div className={style["social-links-container"]}>
          <IconButton
            tag="a"
            href="https://www.facebook.com/VIVEDlearning/"
            target="_blank"
            rel="noreferrer"
            icon={{
              strategy: "component",
              icon: <FacebookLogo />,
            }}
            theme="onSecondary"
          />
          <IconButton
            tag="a"
            href="https://twitter.com/vivedlearning"
            target="_blank"
            rel="noreferrer"
            icon={{
              strategy: "component",
              icon: <TwitterLogo />,
            }}
            theme="onSecondary"
          />
          <IconButton
            tag="a"
            href="https://www.instagram.com/vivedlearning"
            target="_blank"
            rel="noreferrer"
            icon={{
              strategy: "component",
              icon: <InstagramLogo />,
            }}
            theme="onSecondary"
          />
          <IconButton
            tag="a"
            href="https://www.youtube.com/c/VivedLearning"
            target="_blank"
            rel="noreferrer"
            icon={{
              strategy: "component",
              icon: <YoutubeLogo />,
            }}
            theme="onSecondary"
          />
        </div>
      </div>
    );
  };

  return (
    <Theme wrap use="secondaryBg">
      <footer className={style["wrapper"]}>
        <div className={style["content-container"]}>
          {renderLogo()}
          {renderLinks()}
          <div>{renderSocialIconButtons()}</div>
        </div>
        <div className={style["copyright-container"]}>
          <Typography
            use="body2"
            theme="onSecondary"
            style={{ padding: "16px" }}
          >
            Copyright &copy;{currentYear} VIVED
          </Typography>
        </div>
      </footer>
    </Theme>
  );
};
