import { FileUploader } from "Infrastructure/S3/uploadFile";
import { UpdateActivityData } from "Infrastructure/VivedAPI/calls/updateActivityData";
import { ActivityDataSaver } from "UseCases/fetchAllActivities";

export function makeActivityDataSaver(uploadFile: FileUploader, updateActivityData: UpdateActivityData): ActivityDataSaver {
  return async function (activityId: string, activityData: string) {
    
    let newFileName
    try {
      newFileName = await uploadFile(activityData, "json", "DataVariants")
    } catch(e: any) {
      throw new Error(e)
    }

    try {
      await updateActivityData(activityId, {data: newFileName})
    } catch(e: any) {
      throw new Error(e)
    }
  }
}