import { TemplatePM, TemplateVM } from "PresentationManagers/TemplatePM";
import { useContext, useEffect, useRef, useState } from "react";
import ReactAppContext from "React/ReactAppContext";

export const useActivityTemplateListViewModel = (): TemplateVM => {
  const appContext = useContext(ReactAppContext);
  const pm = useRef<TemplatePM>();
  const [viewModel, setViewModel] = useState<TemplateVM>({
    activityIds: []
  });

  useEffect(() => {
    if (!pm.current && appContext) {
      pm.current = new TemplatePM(appContext.entityContext, (vm) => {
        setViewModel(vm);
      });
    }

    return () => {
      pm.current?.dispose();
    };
  }, [appContext]);

  return viewModel;
};
