import { AppEntities } from "@vived/app";

export type OnActivityChange = () => void;

export interface Activity {
  id: string;
  image: string;
  description: string;
  title: string;

  setDataGetter: (getter: () => Promise<string>) => void;
  getActivityData: () => Promise<string>;
  setData: (data: string) => void;
  setAndSaveData: (data: string) => Promise<void>;
  setActivityDataSaver: (
    saver: (id: string, data: string) => Promise<void>
  ) => void;

  addObserver: (obs: OnActivityChange) => void;
  removeObserver: (obs: OnActivityChange) => void;
}

export function makeActivity(id: string): Activity {
  return new ActivityImp(id);
}

class ActivityImp implements Activity {
  private observers = new AppEntities.ObserverList<void>();

  private memoizedId = new AppEntities.MemoizedString(
    "",
    this.observers.notify
  );
  get id(): string {
    return this.memoizedId.val;
  }
  set id(val: string) {
    this.memoizedId.val = val;
  }

  private _activityData = "";
  private activityGetter = (): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      console.warn("No activity getter was set");
      resolve("");
    });
  };
  getActivityData = (): Promise<string> => {
    return new Promise<string>((resolve) => {
      if (this._activityData) {
        resolve(this._activityData);
      } else {
        this.activityGetter().then((data) => {
          this._activityData = data;
          resolve(data);
        });
      }
    });
  };
  setDataGetter = (getter: () => Promise<string>) => {
    this.activityGetter = getter;
  };
  setData = (data: string) => {
    this._activityData = data;
  };

  private dataSaver = (id: string, data: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      console.warn("No activity saver was set");
      resolve();
    });
  };

  setAndSaveData = (data: string): Promise<void> => {
    this._activityData = data;
    return this.dataSaver(this.id, this._activityData);
  };

  setActivityDataSaver = (
    saver: (id: string, data: string) => Promise<void>
  ): void => {
    this.dataSaver = saver;
  };

  private memoizedImage = new AppEntities.MemoizedString(
    "",
    this.observers.notify
  );
  get image(): string {
    return this.memoizedImage.val;
  }
  set image(val: string) {
    this.memoizedImage.val = val;
  }

  private memoizedTitle = new AppEntities.MemoizedString(
    "",
    this.observers.notify
  );
  get title(): string {
    return this.memoizedTitle.val;
  }
  set title(val: string) {
    this.memoizedTitle.val = val;
  }

  private memoizedDescription = new AppEntities.MemoizedString(
    "",
    this.observers.notify
  );
  get description(): string {
    return this.memoizedDescription.val;
  }
  set description(val: string) {
    this.memoizedDescription.val = val;
  }

  private memoizedChannelId = new AppEntities.MemoizedString(
    "",
    this.observers.notify
  );
  get channelId(): string {
    return this.memoizedChannelId.val;
  }
  set channelId(val: string) {
    this.memoizedChannelId.val = val;
  }

  private _assets: string[] = [];
  get assets(): string[] {
    return this._assets;
  }
  set assets(val: string[]) {
    this._assets = val;
    this.observers.notify();
  }

  private lessonPlanVal: string | undefined = undefined;
  get lessonPlan(): string | undefined {
    return this.lessonPlanVal;
  }
  set lessonPlan(val: string | undefined) {
    if (val !== this.lessonPlan) {
      this.lessonPlanVal = val;
      this.observers.notify();
    }
  }

  private studentGuideVal: string | undefined = undefined;
  get studentGuide(): string | undefined {
    return this.studentGuideVal;
  }
  set studentGuide(val: string | undefined) {
    if (val !== this.studentGuide) {
      this.studentGuideVal = val;
      this.observers.notify();
    }
  }

  addObserver = (observer: OnActivityChange) => {
    this.observers.add(observer);
  };

  removeObserver = (observer: OnActivityChange) => {
    this.observers.remove(observer);
  };

  constructor(id: string) {
    this.id = id;
  }
}
