import { EntityContext } from "Entities/EntityContext";
import { AppState } from "Entities/AppState";

export interface LoadingResourcesVM {
  resourcesLoaded: boolean,
  errorMessage: string
}

export class LoadingResourcesPM {
  private appState: AppState;
  private updateView: (vm: LoadingResourcesVM) => void;

  dispose = () => {
    this.appState.removeResourcesObserver(this.doUpdateView);
  };

  private doUpdateView = () => {
    const vm: LoadingResourcesVM = {
      resourcesLoaded: this.appState.isResourcesLoaded,
      errorMessage: this.appState.fetchResourcesError
  };
    this.updateView(vm);
  };

  constructor(
    entities: EntityContext,
    updateView: (vm: LoadingResourcesVM) => void
  ) {
    this.appState = entities.appState;
    entities.appState.addResourcesObserver(this.doUpdateView);

    this.updateView = updateView;
    this.doUpdateView();
  }
}
