import { ActiveActivity } from "Entities/ActiveActivity";
import { Activity } from "Entities/Activity";
import { EntityContext } from "Entities/EntityContext";
import { ResourceRepository } from "Entities/ResourceRepository";
import { StudentRepository } from "Entities/StudentRepository";

export interface ActivityOptions {
  title: string;
  canEdit: boolean;
  startInAuthor: boolean;
}

export interface ActivityPlayerVM {
  show: boolean;
  activityOptions: ActivityOptions;
  activityDataString: string;
  saveCallback: (dataStringToSave: string) => Promise<void>;
}

export class ActivityPlayerPM {
  private activeActivity: ActiveActivity;
  private studentRepo: StudentRepository;
  private resourceRepo: ResourceRepository;

  private updateView: (vm: ActivityPlayerVM) => void;

  dispose = () => {
    this.activeActivity.removeObserver(this.doUpdateView);
  };

  private doUpdateView = () => {
    const activityOptions: ActivityOptions = {
      title: "",
      canEdit: this.activeActivity.canAuthor,
      startInAuthor: this.activeActivity.canAuthor,
    };
    const vm: ActivityPlayerVM = {
      show: this.activeActivity.state === "READY",
      activityDataString: this.activeActivity.activityData,
      activityOptions: activityOptions,
      saveCallback: () => {
        return new Promise<void>((resolve, reject) => {
          resolve();
        });
      },
    };

    if (this.activeActivity.state === "READY") {
      let activity: Activity | undefined = undefined;

      if (this.resourceRepo.hasActivity(this.activeActivity.activityID)) {
        activity = this.resourceRepo.getActivity(
          this.activeActivity.activityID
        );
      } else if (this.studentRepo.has(this.activeActivity.activityID)) {
        activity = this.studentRepo.get(this.activeActivity.activityID);
      }

      if (activity) {
        vm.activityOptions.title = activity.title;
        vm.saveCallback = activity.setAndSaveData;
      }
    }

    this.updateView(vm);
  };

  constructor(
    entities: EntityContext,
    updateView: (vm: ActivityPlayerVM) => void
  ) {
    this.activeActivity = entities.activeActivity;
    this.studentRepo = entities.studentRepository;
    this.resourceRepo = entities.resourceRepository;
    this.activeActivity.addObserver(this.doUpdateView);

    this.updateView = updateView;
    this.doUpdateView();
  }
}
