import { AppEntities } from "@vived/app";
import { Activity } from "./Activity";

export type OnActivityAdded = (activity: Activity) => void;
export type OnActivityRemoved = (id: string) => void;

export interface StudentRepository {
    add(activity: Activity): void;
    has(id: string): boolean;
    get(id: string): Activity;    
    getAll(): Activity[];
    remove(id: string): void;
    removeAll(): void;
    addActivityAddedObserver(observer: OnActivityAdded): void;
    removeActivityAddedObserver(observer: OnActivityAdded): void;
    addActivityRemovedObserver(observer: OnActivityRemoved): void;
    removeActivityRemovedObserver(observer: OnActivityRemoved): void;
}

export function makeStudentRepository(): StudentRepository {
    return new StudentRepositoryImp();
}

class StudentRepositoryImp implements StudentRepository {
    private activityLookup = new Map<string, Activity>();
    private addObservers = new AppEntities.ObserverList<Activity>();
    private removeObservers = new AppEntities.ObserverList<string>();

    add = (activity: Activity): void => {
        if (this.activityLookup.has(activity.id)) {
            throw new Error(`Activity ID ${activity.id} already exists`);
        }

        this.activityLookup.set(activity.id, activity);
        this.addObservers.notify(activity);
    }

    has = (id: string): boolean => {
        const activity = this.activityLookup.get(id);
        return activity? true: false;
    }

    get = (id: string): Activity => {
        const activity = this.findActivity(id);
        return activity;
    }    

    getAll = (): Activity[] => {
        return Array.from(this.activityLookup.values());
    }

    remove = (id: string): void => {
        const activity = this.findActivity(id);

        if (!activity) return;

        this.activityLookup.delete(id);
        this.removeObservers.notify(id);
    }

    removeAll = (): void => {
        if (this.activityLookup.size === 0) return;

        const allActivityIds = Array.from(this.activityLookup.keys());
        allActivityIds.forEach((id) => this.remove(id));

        this.activityLookup.clear();
    }

    addActivityAddedObserver = (observer: OnActivityAdded): void => {
        this.addObservers.add(observer);
    }

    removeActivityAddedObserver = (observer: OnActivityAdded): void => {
        this.addObservers.remove(observer);
    }

    addActivityRemovedObserver = (observer: OnActivityRemoved): void => {
        this.removeObservers.add(observer);
    }

    removeActivityRemovedObserver = (observer: OnActivityRemoved): void => {
        this.removeObservers.remove(observer);
    }

    private findActivity(id: string): Activity {
        const activity = this.activityLookup.get(id);
        if (!activity) {
            throw new Error("[Students] Could not find activity by ID " + id);
        }

        return activity;
    }

}