import { Typography } from "@rmwc/typography";
import "@rmwc/typography/styles";

import style from "./AccessFreeContent.module.scss";

export const AccessFreeContent = () => {
  const joinCode: string = "095b7659";

  return (
    <div className={style["container"]}>
      <Typography tag="h2" use="heading6">
        Access Free Content
      </Typography>
      <Typography tag="p" use="body1">
        Get started today with your free access to the Vived Learning Platform
        for the remainder of the 2021/22 school year.
      </Typography>
      <Typography tag="p" use="body1">
        Join Code: <b className={style["join-code"]}>{joinCode}</b>
      </Typography>
      <ol className={style["list"]}>
        <Typography tag="li" use="body1">
          Go to{" "}
          <a
            href="https://vlp.vivedlearning.com"
            target="_blank"
            rel="noreferrer"
          >
            https://vlp.vivedlearning.com
          </a>
        </Typography>
        <Typography tag="li" use="body1">
          Register an account and verify your email address
        </Typography>
        <Typography tag="li" use="body1">
          Click "Browse & Join Channels"
        </Typography>
        <Typography tag="li" use="body1">
          Click "Join With Code" and enter {joinCode}
        </Typography>
      </ol>
    </div>
  );
};
