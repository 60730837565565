import { CardWithImageAndTitle } from "React/Components/CardWithImageAndTitle/CardWithImageAndTitle";
import { useStudentActivityViewModel } from "React/Hooks/useStudentActivityViewModel";

export interface StudentActivityCardContainerProps {
  activityId: string;
}

export const StudentActivityCardContainer = ({
  activityId,
}: StudentActivityCardContainerProps) => {
  const { primaryAction, title, imageSrc } =
    useStudentActivityViewModel(activityId);

  return (
    <CardWithImageAndTitle
      image={imageSrc}
      title={title}
      primaryAction={primaryAction}
    />
  );
};
