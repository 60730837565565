import { MemoizedBoolean, ObserverList } from "@vived/app/lib/Entities";

export type OnActiveActivityChange = () => void;

export type ActivityState = "NONE" | "LOADING" | "READY" | "ERROR";

export interface AuxiliaryContent {
  label: string;
  fileSrc: string;
}

export interface ActiveActivity {
  state: ActivityState;
  activityID: string;
  activityData: string;
  errorMessage: string;
  canAuthor: boolean;

  auxiliaryContent1?: AuxiliaryContent;

  setError: (message: string) => void;
  setActivityIsReady: (activityData: string) => void;
  setLoadingActivity: (id: string) => void;
  clearActiveActivity: () => void;

  addObserver: (observer: OnActiveActivityChange) => void;
  removeObserver: (observer: OnActiveActivityChange) => void;
}

export function makeActiveActivity(): ActiveActivity {
  return new ActiveActivityImp();
}

class ActiveActivityImp implements ActiveActivity {
  private observers = new ObserverList<void>();

  private _state: ActivityState = "NONE";
  get state(): ActivityState {
    return this._state;
  }

  private _activityID = "";
  get activityID(): string {
    return this._activityID;
  }

  private _activityData = "";
  get activityData(): string {
    return this._activityData;
  }

  private _error = "";
  get errorMessage(): string {
    return this._error;
  }

  private memoizedCanAuthor = new MemoizedBoolean(false, this.observers.notify);
  get canAuthor() {
    return this.memoizedCanAuthor.val;
  }
  set canAuthor(val: boolean) {
    this.memoizedCanAuthor.val = val;
  }

  private _auxiliaryContent1?: AuxiliaryContent;
  get auxiliaryContent1() {
    return this._auxiliaryContent1;
  }
  set auxiliaryContent1(val: AuxiliaryContent | undefined) {
    this._auxiliaryContent1 = val;
    this.observers.notify();
  }

  setError = (message: string): void => {
    this._error = message;
    this._state = "ERROR";
    this.observers.notify();
  };
  setActivityIsReady = (activityData: string): void => {
    this._activityData = activityData;
    this._state = "READY";
    this.observers.notify();
  };
  setLoadingActivity = (id: string): void => {
    this._activityID = id;
    this._state = "LOADING";
    this.observers.notify();
  };

  clearActiveActivity = (): void => {
    this._state = "NONE";
    this._error = "";
    this._activityID = "";
    this._activityData = "";
    this._auxiliaryContent1 = undefined;
    this.observers.notify();
  };

  addObserver = (observer: OnActiveActivityChange): void => {
    this.observers.add(observer);
  };

  removeObserver = (observer: OnActiveActivityChange): void => {
    this.observers.remove(observer);
  };
}
