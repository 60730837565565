import { Typography } from "@rmwc/typography";
import "@rmwc/typography/styles";

import { AgendaItem } from "React/Components/AgendaItem/AgendaItem";

import style from "./SessionAgenda.module.scss";

export const SessionAgenda = () => {
  return (
    <div className={style["container"]}>
      <Typography tag="h3" use="subtitle1" className={style["heading"]}>
        Agenda
      </Typography>
      <div>
        <AgendaItem
          timeframe="2:45 - 2:50pm"
          line1="Presenter & Session Introduction"
        />
        <AgendaItem
          timeframe="2:50 - 3:05pm"
          line1="Introduction to VIVED 3D Models"
          line2="Activity: Explore a Simulation"
          line3="Using VIVED Models in the Classroom"
        />
        <AgendaItem
          timeframe="3:05 - 3:20pm"
          line1="Exploring an Interactive 3D Model from the Student Perspective"
          line2="Activity: Create a Presentation Using VIVED’s Models and Authoring Tool"
          line3="Using VIVED Models in the Classroom"
        />
        <AgendaItem timeframe="3:20 - 3:27pm" line1="Q&A & Feedback Gallery" />
        <AgendaItem timeframe="3:27 - 3:30pm" line1="Wrap-up and Exit Ticket" />
      </div>
    </div>
  );
};
